import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col pl-4 pb-2"
}
const _hoisted_2 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_3 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-full flex flex-col space-y-4 px-4 pt-3" }
const _hoisted_6 = {
  key: 0,
  class: "w-full mdlg:!w-[480px] lg:!w-[480px] h-[calc(100vh-160px)] relative"
}
const _hoisted_7 = { class: "flex flex-col w-full" }
const _hoisted_8 = { class: "w-full flex flex-row space-x-2 justify-center items-center" }
const _hoisted_9 = {
  key: 1,
  class: "relative h-auto w-full py-3 flex flex-col space-y-2"
}
const _hoisted_10 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_11 = { class: "w-full grid grid-cols-2 gap-2" }
const _hoisted_12 = { class: "col-span-1 flex flex-col" }
const _hoisted_13 = { class: "col-span-1 flex flex-col z-20" }
const _hoisted_14 = { class: "w-full flex flex-col space-y-1 pt-1" }
const _hoisted_15 = { class: "w-full grid grid-cols-3 gap-3" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "w-full flex flex-col pt-2 space-y-2" }
const _hoisted_18 = {
  key: 0,
  class: "w-full flex flex-row rounded-[8px] bg-primary-50 px-3 py-3"
}
const _hoisted_19 = { class: "pl-1" }
const _hoisted_20 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 pt-1"
}
const _hoisted_21 = { class: "w-full flex flex-col space-y-2 pt-1" }
const _hoisted_22 = {
  key: 1,
  class: "w-full flex flex-col space-y-4"
}
const _hoisted_23 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_24 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin  h-5 w-5 text-primary-400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_25 = { class: "w-full relative h-[8px] rounded-[6px] bg-grey-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: _ctx.isEdit ? ' Update Offer' : _ctx.pageTitle,
        goBackHandler: _ctx.goBackHandler
      }, _createSlots({
        "extra-topbar-row": _withCtx(() => [
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(`px-4 py-1 ${
                  _ctx.selectedSection == item.id ? 'bg-primary-main' : 'bg-grey-50'
                }  rounded-[20px]  flex flex-row items-center justify-center cursor-pointer`),
                        onClick: ($event: any) => (_ctx.selectedSection = item.id)
                      }, [
                        _createVNode(_component_app_normal_text, {
                          class: _normalizeClass(`${
                    _ctx.selectedSection == item.id ? '!text-white' : '!text-grey-700'
                  }`),
                          "custom-class": 'whitespace-nowrap'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["class"])
                      ], 10, _hoisted_4))
                    }), 128))
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(`${
            _ctx.offerCreationStep.current == _ctx.offerCreationStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offerCreationStep.total, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item,
                    class: _normalizeClass(`${
              _ctx.offerCreationStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == _ctx.offerCreationStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`)
                  }, null, 2))
                }), 128))
              ], 2))
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_form_wrapper, {
              ref: "formComp",
              parentRefs: _ctx.parentRefs,
              class: "w-full flex flex-col space-y-4 items-center justify-center"
            }, {
              default: _withCtx(() => [
                (_ctx.selectedSection == 'offer_media')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      ((!_ctx.isEdit && _ctx.newShoplist.file) || (_ctx.isEdit && _ctx.singleStory))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentBase), {
                              class: "w-full h-full border-[1px] border-grey-200 dark:!border-grey-700 !rounded-[10px]",
                              file: _ctx.newShoplist.file,
                              file_url: _ctx.newShoplist.file_url,
                              type: _ctx.newShoplist.type,
                              ref: "mediaEditorRef",
                              "base-image": _ctx.newShoplist.base_image,
                              "onUpdate:baseImage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newShoplist.base_image) = $event)),
                              "base-video": _ctx.newShoplist.base_video,
                              "onUpdate:baseVideo": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newShoplist.base_video) = $event)),
                              data: [_ctx.singleStory],
                              showSettings: _ctx.showSettings,
                              hideExtraShoplistInfo: _ctx.newShoplist.instagram_data != undefined,
                              "has-edit": true,
                              isFullScreen: false,
                              listenToSpaceBar: false
                            }, null, 40, ["file", "file_url", "type", "base-image", "base-video", "data", "showSettings", "hideExtraShoplistInfo"]))
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_app_file_attachment, {
                          class: "w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed",
                          "onUpdate:modelValue": _ctx.handleFileSelected,
                          "onUpdate:localFileUrl": _ctx.handleFileURLReady,
                          "is-wrapper": "",
                          accept: 'image/jpeg,image/gif,image/png,video/mp4'
                        }, {
                          content: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                  _createTextVNode(" Choose media (image/video) ")
                                ])),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["onUpdate:modelValue", "onUpdate:localFileUrl"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.selectedSection == 'offer_details')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_app_text_field, {
                              "has-title": true,
                              placeholder: 'Enter Title',
                              type: "text",
                              padding: "px-3 py-2",
                              name: 'Title',
                              "custom-class": '!bg-grey-50',
                              rules: [_ctx.FormValidations.RequiredRule],
                              modelValue: _ctx.newShoplist.title,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newShoplist.title) = $event))
                            }, {
                              title: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode(" Offer Title ")
                              ])),
                              _: 1
                            }, 8, ["rules", "modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_app_text_field, {
                              "has-title": true,
                              type: "date",
                              placeholder: 'Select Date',
                              "custom-class": '!bg-grey-50',
                              padding: "px-3 py-2",
                              "prevent-back-date": true,
                              "miminum-date": new Date().toString(),
                              rules: [_ctx.FormValidations.RequiredRule],
                              modelValue: _ctx.newShoplist.expires_on,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newShoplist.expires_on) = $event))
                            }, {
                              title: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode(" Valid Till ")
                              ])),
                              _: 1
                            }, 8, ["miminum-date", "rules", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_app_normal_text, { class: "w-full text-left !font-semibold !text-gray-700 dark:!text-gray-400" }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode(" Products ")
                            ])),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newShoplist.products, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "flex flex-col space-y-1",
                                key: index
                              }, [
                                _createVNode(_component_app_image_loader, {
                                  photoUrl: item.image_url,
                                  class: "!h-[90px] col-span-1 relative rounded-[10px] border-[1px] border-grey-500"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", {
                                      class: "absolute top-[-5px] right-[-5px] h-[20px] w-[20px] flex justify-center items-center bg-grey-200 dark:!bg-grey-white rounded-full cursor-pointer",
                                      onClick: ($event: any) => (_ctx.removeProduct(index))
                                    }, [
                                      _createVNode(_component_app_icon, {
                                        name: 'close',
                                        customClass: '!h-[10px]'
                                      })
                                    ], 8, _hoisted_16)
                                  ]),
                                  _: 2
                                }, 1032, ["photoUrl"]),
                                _createVNode(_component_app_normal_text, { class: "!text-left" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.price, false, "ngn")), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            }), 128)),
                            _createElementVNode("div", {
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showAddProduct())),
                              class: "!h-[90px] col-span-1 rounded-[10px] border-[1px] border-grey-500 border-dashed flex items-center justify-center cursor-pointer"
                            }, [
                              _createVNode(_component_app_icon, {
                                name: 'plus-grey',
                                class: "!h-[15px]"
                              })
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        (!_ctx.canSetReward)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              _createVNode(_component_app_normal_text, { class: "!text-primary-500 dark:!text-primary-500 !text-left" }, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode(" This offer has other merchant products. You won't be able to customize your offer once you add an affiliate product. ")
                                ])),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: _normalizeClass(["w-full flex flex-col", `${!_ctx.canSetReward ? 'opacity-50' : ''}`])
                        }, [
                          _createVNode(_component_AppTextField, {
                            "focus-border": 'border-primary-400',
                            "has-title": true,
                            placeholder: 'Enter percentage',
                            name: "Cashback Reward",
                            rules: [_ctx.FormValidations.RequiredRule],
                            required: true,
                            type: "tel",
                            disabled: !_ctx.canSetReward,
                            modelValue: _ctx.newShoplist.reward_percentage,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newShoplist.reward_percentage) = $event)),
                            "update-value": _ctx.newShoplist.reward_percentage
                          }, {
                            title: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode("Cashback Reward ")
                            ])),
                            "inner-suffix": _withCtx(() => [
                              _createElementVNode("span", _hoisted_19, [
                                _createVNode(_component_app_normal_text, { color: 'text-grey-700' }, {
                                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                                    _createTextVNode("%")
                                  ])),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }, 8, ["rules", "disabled", "modelValue", "update-value"])
                        ], 2)
                      ]),
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createVNode(_component_app_select, {
                              options: _ctx.categoryOptions,
                              "has-title": true,
                              placeholder: 'Select Category',
                              "custom-class": '!bg-grey-50',
                              paddings: 'px-4 py-3',
                              "auto-complete": "",
                              modelValue: _ctx.newShoplist.shipping_category_id,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newShoplist.shipping_category_id) = $event)),
                              theme: _ctx.Logic.Common.currentAppTheme(),
                              required: ""
                            }, {
                              title: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createTextVNode(" Category ")
                              ])),
                              _: 1
                            }, 8, ["options", "modelValue", "theme"]),
                            _createVNode(_component_app_select, {
                              "has-title": true,
                              "placeholder-color": 'placeholder:text-black',
                              "default-size": 'w-full',
                              placeholder: 'Select',
                              "custom-class": '!bg-grey-50',
                              paddings: 'px-4 py-3',
                              ref: 'product_size',
                              theme: _ctx.Logic.Common.currentAppTheme(),
                              options: _ctx.packageDimensionOptions,
                              modelValue: _ctx.newShoplist.package_dimension_id,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newShoplist.package_dimension_id) = $event)),
                              required: ""
                            }, {
                              title: _withCtx(() => _cache[17] || (_cache[17] = [
                                _createTextVNode(" Package Dimension ")
                              ])),
                              _: 1
                            }, 8, ["theme", "options", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_app_text_field, {
                          isTextarea: "",
                          hasTitle: true,
                          placeholder: "Enter description",
                          "text-area-row": "4",
                          type: "text",
                          maxCharacter: 250,
                          "custom-class": '!bg-grey-50',
                          name: "Description",
                          rules: [_ctx.FormValidations.RequiredRule],
                          modelValue: _ctx.newShoplist.description,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newShoplist.description) = $event))
                        }, {
                          title: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode(" Description ")
                          ])),
                          _: 1
                        }, 8, ["rules", "modelValue"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["parentRefs"]),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "h-[70px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, {
            "custom-class": "pt-4",
            class: "!z-[20000]"
          }, {
            default: _withCtx(() => [
              (!_ctx.newShoplist.progress.loading)
                ? (_openBlock(), _createBlock(_component_app_button, {
                    key: 0,
                    onClick: _withModifiers(_ctx.continueToNext, ["prevent"]),
                    class: _normalizeClass(`w-full ${_ctx.formIsValid ? '' : 'opacity-50'}`),
                    padding: 'py-3'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isEdit
                ? "Update"
                : _ctx.offerCreationStep.current == _ctx.offerCreationStep.total
                ? "Submit for review"
                : "Continue"), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "class"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_app_normal_text, { class: "!text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.newShoplist.progress.message), 1)
                        ]),
                        _: 1
                      }),
                      (_openBlock(), _createElementBlock("svg", _hoisted_24, _cache[20] || (_cache[20] = [
                        _createElementVNode("circle", {
                          class: "opacity-25",
                          cx: "12",
                          cy: "12",
                          r: "10",
                          stroke: "currentColor",
                          "stroke-width": "4"
                        }, null, -1),
                        _createElementVNode("path", {
                          class: "opacity-75",
                          fill: "currentColor",
                          d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        }, null, -1)
                      ])))
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", {
                        class: "absolute h-full top-0 left-0 rounded-[6px] bg-secondary-500",
                        style: _normalizeStyle(`width: ${_ctx.newShoplist.progress.percentage}%`)
                      }, null, 4)
                    ])
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (!_ctx.isEdit)
          ? {
              name: "extra-topbar",
              fn: _withCtx(() => [
                _createVNode(_component_app_normal_text, { "custom-class": '!text-primary-400 dark:!text-primary-300' }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString("Step " + `(${_ctx.offerCreationStep.current + "/" + _ctx.offerCreationStep.total})`), 1)
                  ]),
                  _: 1
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["title", "goBackHandler"])
    ]),
    _: 1
  }))
}