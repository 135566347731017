<template>
  <div class="w-full flex flex-row h-full items-center justify-center">
    <div
      :class="`w-full h-full mdlg:!w-[70%] lg:!w-[65%]  mdlg:!h-screen ${parentContClass} lg:text-sm mdlg:text-[12px] text-xs grid grid-cols-12  bg-white dark:!bg-black  !font-inter  relative`"
      style="
        padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
      "
      :id="`parentContainer-${uniqueKey}`"
    >
      <!-- Left side -->
      <teleport to="#app" :disabled="Logic.Common.currentBuildType() == 'web'">
        <div
          class="hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col fixed top-0 h-full z-[999999999] md:!pl-4 bg-white dark:bg-black"
          :style="`width: ${sideBarConfig.left.width}px; left: ${sideBarConfig.left.left}px;`"
          :id="`sidbarContainerLeft`"
          v-if="
            showWebSideBar ||
            Logic.Common.currentMediaQuery() != 'md' ||
            (Logic.Common.currentMediaQuery() == 'md' &&
              Logic.Common.currentBuildType() == 'web')
          "
        >
          <app-web-side-bar :tabIsActive="tabIsActive" />
        </div>
      </teleport>

      <div
        class="hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col sticky top-0"
      ></div>

      <!-- Main section -->
      <div
        :class="`col-span-12 mdlg:!col-span-6 md:!col-span-8 flex flex-col mdlg:!border-l-[1px] md:!border-l-[1px]  md:!min-h-screen mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-grey-100  mdlg:dark:!border-grey-700 md:dark:!border-grey-700  relative h-full ${mainSectionClass}  ${
          Logic.Common.currentBuildType() == 'web' ? '' : 'overflow-y-auto'
        }`"
        id="main-section"
      >
        <!-- Top section -->
        <div
          :class="` w-full flex-col flex  sticky top-0 z-[99999999]  bg-white dark:!bg-black  ${topBarStyle} `"
          :style="` ${
            type == 'normal'
              ? 'padding-top: calc(env(safe-area-inset-top) + 16px) !important;'
              : ''
          }`"
        >
          <div
            :class="`w-full flex flex-row items-center justify-between ${
              type == 'normal' ? 'px-4 pb-4' : ''
            }  `"
          >
            <template v-if="type == 'normal'">
              <div class="flex items-center flex-row space-x-3">
                <app-icon
                  @click="
                    goBackHandler ? goBackHandler() : Logic.Common.goBack()
                  "
                  :custom-class="'h-[38px] cursor-pointer'"
                  :name="
                    theme == 'light'
                      ? `${
                          Logic.Common.currentAppTheme() == 'dark'
                            ? 'goback-dark-white'
                            : 'back-arrow-grey'
                        }`
                      : darkBackIcon
                  "
                >
                </app-icon>
                <app-header-text
                  v-if="title"
                  :color="
                    theme == 'light'
                      ? 'text-black dark:!text-white'
                      : 'text-white '
                  "
                >
                  {{ title }}
                </app-header-text>
              </div>

              <div
                class="flex flex-row items-center justify-center"
                v-if="centerTitle"
              >
                <app-header-text
                  :color="
                    theme == 'light'
                      ? 'text-dark  dark:!text-white'
                      : 'text-white '
                  "
                  class="-ml-[3%]"
                >
                  {{ centerTitle }}
                </app-header-text>
              </div>
            </template>

            <template v-if="type == 'image_card'">
              <app-image-loader
                :customClass="`w-full flex flex-col relative ${imageCardStyle}`"
                customStyle="padding-top: calc(env(safe-area-inset-top) + 16px) !important; height: calc(env(safe-area-inset-top) + 140px) !important;"
                :photoUrl="photoUrl"
              >
                <div class="flex flex-row items-center space-x-3 z-50 px-4">
                  <div
                    :class="`w-[40px] h-[40px] xs:w-[35px] xs:h-[35px] rounded-full flex flex-row items-center justify-center   bg-[#E36089]  bg-opacity-90  cursor-pointer`"
                    @click="Logic.Common.goBack()"
                  >
                    <app-icon
                      :name="'arrow-left-white'"
                      :customClass="'h-[14px]'"
                    />
                  </div>
                  <app-header-text v-if="title" :color="'text-white'">
                    {{ title }}
                  </app-header-text>
                </div>
                <slot name="extra-imagetext" />
                <div
                  v-if="hasImageBackground"
                  :class="`absolute top-0 left-0 w-full h-full ${imageCardStyle}`"
                ></div>

                <div
                  class="w-full flex flex-row items-end justify-end absolute bottom-0 left-0 z-50 px-4"
                  v-if="brandImage"
                >
                  <div class="w-[80px]">
                    <app-image-loader
                      :photoUrl="brandImage"
                      customClass="h-[80px] w-[80px] rounded-full bg-white -mb-[40px] border-[3px] border-white"
                    >
                    </app-image-loader>
                  </div>
                  <slot name="extra-topbar-special" />
                </div>
              </app-image-loader>
            </template>

            <div class="flex flex-row space-x-3 items-center">
              <app-icon
                v-if="hasNotify"
                :name="'notify-circle'"
                :customClass="'!h-[38px]'"
              />

              <app-icon
                v-if="hasSearch"
                :name="'search-circle'"
                :customClass="'!h-[38px]'"
              />
              <app-icon
                v-if="hasCart"
                :name="'cart-circle'"
                :customClass="'!h-[38px]'"
              />

              <slot name="extra-topbar" />
            </div>
          </div>

          <slot name="extra-topbar-row" />
        </div>

        <slot />

        <!-- Bottom blank -->
        <fixed-container
          v-if="showBottomPadding"
          :class="`w-full  !bottom-0 !left-0  z-[9999999] ${bottomBlankStyle}`"
          style="height: calc(env(safe-area-inset-bottom))"
        ></fixed-container>
      </div>

      <!-- Right side -->
      <div class="hidden col-span-3 mdlg:!flex flex-col sticky top-0"></div>

      <div
        class="hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full"
        :style="`width: ${sideBarConfig.left.width}px; right: ${sideBarConfig.left.left}px;`"
        :id="`sidbarContainerRight`"
      >
        <app-board-side-bar />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import FixedContainer from "../AppCommon/FixedContainer.vue";
import { currentLayout, tabIsActive } from "../../composable";
import { Logic } from "../../index";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import AppIcon from "../AppIcon";
import AppImageLoader from "../AppImageLoader";
import { AppWebSideBar, AppBoardSideBar } from "../AppNavigator";
import { AppHeaderText } from "../AppTypography";

export default defineComponent({
  components: {
    AppIcon,
    AppHeaderText,
    AppImageLoader,
    AppWebSideBar,
    AppBoardSideBar,
    FixedContainer,
  },
  props: {
    hasNotify: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    topBarStyle: {
      type: String,
      default: "",
    },
    mainSectionClass: {
      type: String,
      default: "",
    },
    hasCart: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "light",
    },
    title: {
      type: String,
      default: "",
    },
    darkBackIcon: {
      type: String,
      default: "goback-dark",
    },
    type: {
      type: String,
      default: "normal",
    },
    photoUrl: {
      type: String,
      default: "",
    },
    brandImage: {
      type: String,
      default: "",
    },
    imageCardStyle: {
      type: String,
      default: "",
    },
    mainImageCardStyle: {
      type: String,
      default: "",
    },
    hasImageBackground: {
      type: Boolean,
      default: true,
    },
    centerTitle: {
      type: String,
      default: "",
    },
    goBackHandler: {
      type: Function,
      required: false,
    },
    bottomBlankStyle: {
      type: String,
      default: "bg-white dark:bg-black",
    },
    parentContClass: {
      type: String,
      default: "",
    },
    showBottomPadding: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["go-back", "right-button-clicked"],
  name: "SubPageLayout",
  setup() {
    const route = Logic.Common.route;

    const uniqueKey = Logic.Common.makeid(13);
    const pagePath = ref("");

    const sideBarConfig = reactive({
      left: {
        width: 0,
        left: 0,
      },
      right: {
        width: 0,
        left: 0,
      },
    });

    const showWebSideBar = ref(false);

    const calculateSideBarPosition = () => {
      const parentContainer = document.getElementById(
        `parentContainer-${uniqueKey}`
      );
      if (parentContainer) {
        const parentWidth = parentContainer.offsetWidth;
        const sidebarWidth =
          parentWidth *
          (Logic.Common.currentMediaQuery() != "md" ? 0.25 : 0.33); // 25% or 33% of parent width
        const parentLeftOffset = parentContainer.offsetLeft;

        // Calculate the right edge of the parent container
        const parentRightEdge = parentLeftOffset + parentWidth;

        // Update sideBarConfig for both left and right sidebars
        sideBarConfig.left = {
          width: sidebarWidth,
          left: parentLeftOffset,
        };
        sideBarConfig.right = {
          width: sidebarWidth,
          left: parentRightEdge,
        };
      }
    };

    watch(currentLayout, () => {
      if (currentLayout.value?.path.toString() === pagePath.value) {
        showWebSideBar.value = true;
      } else {
        showWebSideBar.value = false;
      }

      if (
        Logic.Common.currentMediaQuery() === "md" &&
        Logic.Common.currentBuildType() == "mobile"
      ) {
        setTimeout(calculateSideBarPosition, 100);
      } else {
        calculateSideBarPosition();
      }
    });

    onMounted(() => {
      pagePath.value = route?.path || "";
    });

    return {
      Logic,
      sideBarConfig,
      currentLayout,
      route,
      showWebSideBar,
      uniqueKey,
      tabIsActive,
    };
  },
});
</script>
