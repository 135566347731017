import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-[340px] xs:!w-[270px] flex flex-col space-y-2 cursor-pointer"
}
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_3 = { class: "flex flex-col space-y-1" }
const _hoisted_4 = { class: "flex flex-col space-y-1" }
const _hoisted_5 = { class: "flex flex-row space-x-1 justify-end !text-right" }
const _hoisted_6 = { class: "!font-semibold !text-[#9D4EDD] !text-[12px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_media_box = _resolveComponent("app-media-box")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_media_box, {
          data: _ctx.item.data,
          class: _normalizeClass(`h-[270px] xs:!h-[230px] !rounded-[10px] relative ${_ctx.mediaBoxStyle} border-[1px] border-gray-200 dark:border-gray-700`),
          isVideo: !_ctx.item.data.is_grid
        }, null, 8, ["data", "class", "isVideo"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_normal_text, { class: "!font-semibold !text-left !text-[13px]" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.data.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_app_normal_text, { class: "!text-[13px] !text-left" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.item.data.price || 0)), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_normal_text, {
              class: _normalizeClass(`!text-grey-600 dark:!text-gray-200 !text-[11px] !text-right ${
            _ctx.item.data.data?.expires_at ? '' : '!invisible'
          }`)
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Expires " + _toDisplayString(_ctx.Logic.Common.timeFromNow(_ctx.item.data.data?.expires_at)), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_app_normal_text, { customClass: '!text-[11px] !text-grey-400 dark:!text-gray-200  !text-right !font-light !whitespace-nowrap' }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, " ₦" + _toDisplayString(_ctx.numberToAbbrev(_ctx.item.data.points || 0)), 1),
                  _cache[0] || (_cache[0] = _createTextVNode(" Cashback"))
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}