import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2 flex-grow px-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_3 = { class: "flex flex-col space-y-2 items-center justify-center w-full" }
const _hoisted_4 = { class: "pr-1" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_6 = { class: "w-full flex flex-row justify-end" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-3 pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Edit Profile' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_form_wrapper, {
              parentRefs: _ctx.parentRefs,
              ref: "formComp",
              class: "w-full flex flex-col space-y-4 pt-3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_app_normal_text, { class: "!font-semibold text-left" }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Business Logo ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_app_file_attachment, {
                      "is-wrapper": true,
                      modelValue: _ctx.profileUpdateData.business_logo,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profileUpdateData.business_logo) = $event)),
                      "local-file-url": _ctx.photoUrl,
                      "onUpdate:localFileUrl": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.photoUrl) = $event)),
                      accept: `image/png, image/gif, image/jpeg`,
                      class: "flex flex-row items-center justify-center !w-auto cursor-pointer"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("div", {
                          style: _normalizeStyle(`background-image: url(${_ctx.photoUrl});  background-size: cover;
               background-repeat: no-repeat;
               background-position: center;`),
                          class: "h-[85px] w-[85px] rounded-full bg-gray-100 flex flex-row items-center justify-center"
                        }, [
                          _createVNode(_component_app_icon, {
                            name: 'image-purple',
                            customClass: 'h-[15px]'
                          })
                        ], 4)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "local-file-url"])
                  ])
                ]),
                _createVNode(_component_AppTextField, {
                  "has-title": true,
                  "custom-class": '',
                  padding: "px-3 py-3",
                  placeholder: 'Business Name',
                  "focus-border": 'border-primary-400',
                  rules: [_ctx.FormValidations.RequiredRule],
                  required: "",
                  name: "Business name",
                  modelValue: _ctx.profileUpdateData.name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.profileUpdateData.name) = $event)),
                  "update-value": _ctx.profileUpdateData.name
                }, {
                  title: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("Business Name")
                  ])),
                  "inner-prefix": _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, [
                      _createVNode(_component_app_icon, {
                        name: 'shopping-bag',
                        customClass: 'h-[16px]'
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["rules", "modelValue", "update-value"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Business Handle',
                    hasTitle: true,
                    type: 'text',
                    ref: "usernameRef",
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.username) = $event)),
                    name: 'Handle',
                    rules: [
                _ctx.FormValidations.RequiredRule,
                _ctx.FormValidations.customValidator(
                  _ctx.username.indexOf(' ') <= 0,
                  'Handle cannot contain white space'
                ),
                _ctx.FormValidations.Alphanumeric,
              ],
                    "update-value": _ctx.username,
                    "content-rule": {
                addAfterCount: 0,
                characterToAdd: ``,
                max: 15,
              },
                    required: ""
                  }, {
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, null, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode(" @ ")
                        ])),
                        _: 1
                      })
                    ]),
                    title: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" Business Handle ")
                    ])),
                    _: 1
                  }, 8, ["modelValue", "rules", "update-value"]),
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.resolvingUsername)
                      ? (_openBlock(), _createBlock(_component_app_normal_text, {
                          key: 0,
                          color: 'text-primary-main'
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode(" Resolving handle... ")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (!_ctx.resolvingUsername && _ctx.username != _ctx.AuthUser?.username && _ctx.username)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (!_ctx.usernameExist)
                            ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                key: 0,
                                color: 'text-success-main'
                              }, {
                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                  _createElementVNode("span", { class: "px-1" }, "Available", -1),
                                  _createTextVNode(" ✅ ")
                                ])),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_app_normal_text, {
                                key: 1,
                                color: 'text-error-main'
                              }, {
                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                  _createElementVNode("span", { class: "px-1" }, "Not available", -1),
                                  _createTextVNode(" ❌ ")
                                ])),
                                _: 1
                              }))
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createVNode(_component_AppTextField, {
                  "has-title": true,
                  "is-textarea": "",
                  "custom-class": '',
                  placeholder: 'About this business',
                  "focus-border": 'border-primary-400',
                  modelValue: _ctx.profileUpdateData.about_business,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.profileUpdateData.about_business) = $event)),
                  rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                _ctx.profileUpdateData.about_business.length <= 250,
                'Maximum of 250 Characters'
              ),
            ],
                  "max-character": 250,
                  required: "",
                  "update-value": _ctx.profileUpdateData.about_business
                }, {
                  title: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode(" About Business ")
                  ])),
                  _: 1
                }, 8, ["modelValue", "rules", "update-value"]),
                _createVNode(_component_AppTextField, {
                  "has-title": true,
                  "custom-class": '',
                  padding: "px-3 py-3",
                  placeholder: 'Enter Phone Number',
                  "focus-border": 'border-primary-400',
                  modelValue: _ctx.profileUpdateData.business_phone_number,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.profileUpdateData.business_phone_number) = $event)),
                  rules: [_ctx.FormValidations.RequiredRule],
                  name: "Phone number",
                  type: "tel",
                  required: "",
                  "update-value": _ctx.profileUpdateData.business_phone_number
                }, {
                  title: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode("Business Phone Number")
                  ])),
                  _: 1
                }, 8, ["modelValue", "rules", "update-value"]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_app_normal_text, { class: "!font-semibold w-full border-b-[1px] border-gray-200 pb-2" }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode(" Social Media ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_AppTextField, {
                    "has-title": true,
                    "custom-class": '',
                    placeholder: 'username',
                    "focus-border": 'border-primary-400',
                    modelValue: _ctx.profileUpdateData.facebook,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.profileUpdateData.facebook) = $event)),
                    "update-value": _ctx.profileUpdateData.facebook
                  }, {
                    title: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode(" Facebook ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_AppNormalText, { "custom-class": 'text-grey-700' }, {
                        default: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createTextVNode(" facebook.com/ ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "update-value"]),
                  _createVNode(_component_AppTextField, {
                    "has-title": true,
                    "custom-class": '',
                    placeholder: 'username',
                    "focus-border": 'border-primary-400',
                    modelValue: _ctx.profileUpdateData.instagram,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.profileUpdateData.instagram) = $event)),
                    "update-value": _ctx.profileUpdateData.instagram
                  }, {
                    title: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode(" Instagram ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_AppNormalText, { "custom-class": 'text-grey-700' }, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode(" instagram.com/ ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "update-value"]),
                  _createVNode(_component_AppTextField, {
                    "has-title": true,
                    "custom-class": '',
                    placeholder: '0000000000',
                    "focus-border": 'border-primary-400',
                    modelValue: _ctx.profileUpdateData.whatsapp,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.profileUpdateData.whatsapp) = $event)),
                    typw: "tel",
                    "update-value": _ctx.profileUpdateData.whatsapp
                  }, {
                    title: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode(" Whatsapp ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_AppNormalText, { "custom-class": 'text-grey-700' }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode(" wa.me/ ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "update-value"]),
                  _createVNode(_component_AppTextField, {
                    "has-title": true,
                    "custom-class": '',
                    placeholder: 'https://',
                    "focus-border": 'border-primary-400',
                    rules: [_ctx.FormValidations.WebsiteRule],
                    modelValue: _ctx.profileUpdateData.website,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.profileUpdateData.website) = $event)),
                    "update-value": _ctx.profileUpdateData.website
                  }, {
                    title: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode(" Website ")
                    ])),
                    _: 1
                  }, 8, ["rules", "modelValue", "update-value"])
                ])
              ]),
              _: 1
            }, 8, ["parentRefs"]),
            _cache[28] || (_cache[28] = _createElementVNode("div", { class: "!h-[100px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_app_button, {
                customClass: `w-full ${_ctx.usernameExist ? '!opacity-60' : ''}`,
                padding: 'py-3 xs:py-3',
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.saveProfile())),
                loading: _ctx.loaderSetup.loading
              }, {
                default: _withCtx(() => _cache[29] || (_cache[29] = [
                  _createTextVNode(" Save ")
                ])),
                _: 1
              }, 8, ["customClass", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}