<template>
  <template v-if="Logic.Common.currentBuildType() == 'mobile'">
    <ion-app id="mainApp" class="!bg-transparent">
      <IonRouterOutlet
        class="lg:text-sm mdlg:text-[12px] text-xs !font-inter !bg-transparent"
      ></IonRouterOutlet>
    </ion-app>
  </template>
  <template v-if="Logic.Common.currentBuildType() == 'web'">
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | Shoppoint - Retailers` : `Shoppoint - Retailers`
      }}</template>
    </metainfo>
    <div id="mainApp">
      <router-view
        class="lg:text-sm mdlg:text-[12px] text-xs !font-inter !bg-transparent"
      >
      </router-view>
    </div>
  </template>

  <app-bottom-bar
    :tabs="Logic.Common.bottomBarItems"
    :tab-is-active="tabIsActive"
    v-if="showBottomNav"
    :class="bottomNavStyle"
  />

  <app-side-bar
    v-if="showSideBar"
    :closeBar="
      () => {
        showSideBar = false;
      }
    "
  />

  <app-loader v-if="loaderSetup.loading" :setup="loaderSetup" />
  <app-alert v-if="alertSetup.show" :setup="alertSetup" />
  <app-modal
    v-if="modalSetup.show && !loaderSetup.loading"
    :setup="modalSetup"
  />
</template>
<script lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
import {
  AppBottomBar,
  AppLoader,
  AppModal,
  AppSideBar,
  AppAlert,
} from "@shpt/ui-components";
import { useRouter } from "vue-router";
import { Logic } from "@shpt/logic";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import {
  bottomNavStyle,
  currentLayout,
  routeQueryString,
  showSideBar,
} from "@shpt/ui-components/src/composable";

export default defineComponent({
  components: {
    IonRouterOutlet,
    IonApp,
    AppBottomBar,
    AppLoader,
    AppModal,
    AppSideBar,
    AppAlert,
  },
  setup() {
    const modalSetup = ref(Logic.Common.modalSetup);
    const alertSetup = ref(Logic.Common.alertSetup);

    const showBottomNav = ref<any>();

    const selectedTab = ref("");

    const router = useRouter();

    const tabIsActive = (tabName: string) => {
      const mainName = tabName;

      if (mainName == "base" && router.currentRoute.value.path == "/") {
        return true;
      } else if (
        mainName != "base" &&
        router.currentRoute.value.path.includes(mainName)
      ) {
        selectedTab.value = mainName;
        return true;
      }

      return false;
    };

    onMounted(() => {
      Logic.Common.setCurrentAccount("merchant");
      Logic.Common.watchProperty("showBottomNav", showBottomNav);
      Logic.Common.watchProperty("loaderSetup", loaderSetup);
      Logic.Common.watchProperty("modalSetup", modalSetup);
      Logic.Common.watchProperty("alertSetup", alertSetup);
      Logic.Common.watchProperty("currentLayout", currentLayout);
      Logic.Common.setSideBar();
      Logic.Common.setBottomBar();
      console.log(Logic.Common.currentBuildType());

      if (Logic.Common.currentBuildType() == "web") {
        document.body.classList.add(
          "!bg-[#FAF8F9]",
          "md:!bg-white",
          "dark:!bg-black"
        );
      } else {
        document.body.classList.add("!bg-transparent");
      }
      routeQueryString.value = window.location.search;
    });

    return {
      loaderSetup,
      modalSetup,
      alertSetup,
      showBottomNav,
      bottomNavStyle,
      showSideBar,
      Logic,
      tabIsActive,
    };
  },
});
</script>
<style>
body,
html {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent !important;
}

ion-content {
  --ion-background-color: transparent;
}
</style>
