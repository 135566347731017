import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 px-4 pt-3" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_3 = { class: "w-full flex flex-row space-x-2 justify-center items-center" }
const _hoisted_4 = {
  key: 1,
  class: "w-full mdlg:!w-[480px] lg:!w-[480px] min-h-[calc(100vh-160px)] relative flex flex-col"
}
const _hoisted_5 = {
  key: 1,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_6 = { class: "w-full grid grid-cols-2 gap-3 pb-2" }
const _hoisted_7 = {
  key: 0,
  class: "col-span-1 flex flex-col"
}
const _hoisted_8 = {
  key: 1,
  class: "col-span-1 flex flex-col"
}
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "w-full flex flex-row space-x-2 justify-center items-center" }
const _hoisted_11 = { class: "w-full flex flex-row space-x-2 h-full justify-center items-center" }
const _hoisted_12 = { class: "w-full flex flex-col" }
const _hoisted_13 = { class: "w-full flex flex-col" }
const _hoisted_14 = { class: "w-full flex flex-col" }
const _hoisted_15 = { class: "w-full flex flex-col" }
const _hoisted_16 = { class: "w-full flex flex-col" }
const _hoisted_17 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_18 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_19 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_20 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_21 = {
  key: 4,
  class: "w-full flex flex-col"
}
const _hoisted_22 = { class: "w-full flex flex-col" }
const _hoisted_23 = { class: "w-full flex flex-col" }
const _hoisted_24 = { class: "w-full flex flex-col" }
const _hoisted_25 = { class: "w-full flex flex-col" }
const _hoisted_26 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_27 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_28 = { class: "w-full flex flex-col" }
const _hoisted_29 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_30 = { class: "w-full flex flex-col" }
const _hoisted_31 = { class: "w-full flex flex-col" }
const _hoisted_32 = {
  key: 2,
  class: "w-full flex flex-row"
}
const _hoisted_33 = { class: "w-full flex flex-col" }
const _hoisted_34 = { class: "w-full flex flex-col" }
const _hoisted_35 = { class: "w-full flex flex-col" }
const _hoisted_36 = {
  key: 1,
  class: "w-full flex flex-col space-y-4"
}
const _hoisted_37 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_38 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin  h-5 w-5 text-primary-400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_39 = { class: "w-full relative h-[8px] rounded-[6px] bg-grey-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_app_story_view = _resolveComponent("app-story-view")!
  const _component_app_product = _resolveComponent("app-product")!
  const _component_app_shoplist = _resolveComponent("app-shoplist")!
  const _component_media_editor = _resolveComponent("media-editor")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_content_editable = _resolveComponent("app-content-editable")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: _ctx.isEdit ? 'Update Advert' : _ctx.pageTitle,
        goBackHandler: _ctx.goBackHandler
      }, _createSlots({
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(`${
            _ctx.promotionCreationStep.current == _ctx.promotionCreationStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotionCreationStep.total, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item,
                class: _normalizeClass(`${
              _ctx.promotionCreationStep.current >= item
                ? 'bg-highlight-400'
                : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == _ctx.promotionCreationStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`)
              }, null, 2))
            }), 128))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_form_wrapper, {
              ref: "formComp",
              parentRefs: _ctx.parentRefs,
              class: "w-full flex flex-col space-y-4"
            }, {
              default: _withCtx(() => [
                (_ctx.promotionCreationStep.current == 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_app_select, {
                        "has-title": true,
                        "placeholder-color": 'placeholder:text-black',
                        "default-size": 'w-full',
                        placeholder: 'Select',
                        "custom-class": '!bg-grey-50',
                        paddings: 'px-4 py-3',
                        ref: 'product_size',
                        theme: _ctx.Logic.Common.currentAppTheme(),
                        options: _ctx.advertTypes,
                        modelValue: _ctx.newPromotion.basic.type,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPromotion.basic.type) = $event)),
                        required: ""
                      }, {
                        title: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode(" What is your objective? ")
                        ])),
                        _: 1
                      }, 8, ["theme", "options", "modelValue"]),
                      (
                _ctx.newPromotion.basic.type == 'shoppable' &&
                _ctx.singleStory &&
                (_ctx.newPromotion.basic.shoppable_type == 'shoplist' ||
                  _ctx.newPromotion.basic.shoppable_type == 'product')
              )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_app_file_attachment, {
                              class: "w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed cursor-pointer",
                              "onUpdate:modelValue": _ctx.handleFileSelected,
                              "onUpdate:localFileUrl": _ctx.handleFileURLReady,
                              "is-wrapper": "",
                              accept: 'image/jpeg,image/gif,image/png,video/mp4'
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_3, [
                                  _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                    default: _withCtx(() => _cache[31] || (_cache[31] = [
                                      _createTextVNode(" Choose new media for offer (image/video) ")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            }, 8, ["onUpdate:modelValue", "onUpdate:localFileUrl"])
                          ]))
                        : _createCommentVNode("", true),
                      (
                !_ctx.isEdit &&
                ((_ctx.newPromotionMedia.file && _ctx.newPromotion.basic.type != 'shoppable') ||
                  (_ctx.newPromotion.basic.shoplist &&
                    _ctx.newPromotion.basic.type == 'shoppable' &&
                    _ctx.singleStory))
              )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            (_ctx.newPromotion.basic.type == 'shoppable' && _ctx.singleStory)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (!_ctx.useCustomMediaForShoppable)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        (_ctx.singleStory)
                                          ? (_openBlock(), _createBlock(_component_app_story_view, {
                                              key: 0,
                                              class: "!relative !h-full",
                                              ref: "storyCompRef",
                                              data: [_ctx.singleStory],
                                              showSettings: true,
                                              "has-edit": true,
                                              isFullScreen: false,
                                              listenToSpaceBar: false
                                            }, null, 8, ["data"]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createVNode(_component_app_normal_text, { class: "!font-semibold !text-left" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Selected " + _toDisplayString(_ctx.newPromotion.basic.shoppable_type == "shoplist"
                          ? "offer"
                          : "product"), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createElementVNode("div", _hoisted_6, [
                                          (
                          _ctx.newPromotion.basic.shoppable_data &&
                          _ctx.newPromotion.basic.shoppable_type == 'product'
                        )
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                                _createVNode(_component_app_product, {
                                                  data: _ctx.newPromotion.basic.shoppable_data,
                                                  class: _normalizeClass(`rounded-[6px]`)
                                                }, null, 8, ["data"])
                                              ]))
                                            : _createCommentVNode("", true),
                                          (
                          _ctx.newPromotion.basic.shoppable_data &&
                          _ctx.newPromotion.basic.shoppable_type == 'shoplist'
                        )
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                                _createVNode(_component_app_shoplist, {
                                                  item: _ctx.newPromotion.basic.shoppable_data,
                                                  class: _normalizeClass(`rounded-[6px]`)
                                                }, null, 8, ["item"])
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        (_ctx.newPromotionMedia.file_url)
                                          ? (_openBlock(), _createBlock(_component_media_editor, {
                                              key: 0,
                                              ref: "mediaEditorRef",
                                              file: _ctx.newPromotionMedia.file,
                                              file_url: _ctx.newPromotionMedia.file_url,
                                              type: _ctx.newPromotionMedia.type,
                                              "base-image": _ctx.newPromotionMedia.base_image,
                                              "onUpdate:baseImage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPromotionMedia.base_image) = $event)),
                                              "base-video": _ctx.newPromotionMedia.base_video,
                                              "onUpdate:baseVideo": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPromotionMedia.base_video) = $event))
                                            }, null, 8, ["file", "file_url", "type", "base-image", "base-video"]))
                                          : _createCommentVNode("", true)
                                      ]))
                                ], 64))
                              : _createCommentVNode("", true),
                            (_ctx.newPromotion.basic.type == 'polls')
                              ? (_openBlock(), _createBlock(_component_media_editor, {
                                  key: 1,
                                  ref: "mediaEditorRef",
                                  file: _ctx.newPromotionMedia.file,
                                  file_url: _ctx.newPromotionMedia.file_url,
                                  type: _ctx.newPromotionMedia.type,
                                  "base-image": _ctx.newPromotionMedia.base_image,
                                  "onUpdate:baseImage": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newPromotionMedia.base_image) = $event)),
                                  "base-video": _ctx.newPromotionMedia.base_video,
                                  "onUpdate:baseVideo": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newPromotionMedia.base_video) = $event)),
                                  withPolls: ""
                                }, null, 8, ["file", "file_url", "type", "base-image", "base-video"]))
                              : _createCommentVNode("", true),
                            (_ctx.newPromotion.basic.type == 'website_visit')
                              ? (_openBlock(), _createBlock(_component_media_editor, {
                                  key: 2,
                                  ref: "mediaEditorRef",
                                  file: _ctx.newPromotionMedia.file,
                                  file_url: _ctx.newPromotionMedia.file_url,
                                  type: _ctx.newPromotionMedia.type,
                                  "base-image": _ctx.newPromotionMedia.base_image,
                                  "onUpdate:baseImage": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newPromotionMedia.base_image) = $event)),
                                  "base-video": _ctx.newPromotionMedia.base_video,
                                  "onUpdate:baseVideo": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newPromotionMedia.base_video) = $event)),
                                  withCta: ""
                                }, null, 8, ["file", "file_url", "type", "base-image", "base-video"]))
                              : _createCommentVNode("", true),
                            (_ctx.newPromotion.basic.type == 'banner')
                              ? (_openBlock(), _createBlock(_component_media_editor, {
                                  key: 3,
                                  ref: "mediaEditorRef",
                                  file: _ctx.newPromotionMedia.file,
                                  file_url: _ctx.newPromotionMedia.file_url,
                                  type: _ctx.newPromotionMedia.type,
                                  "base-image": _ctx.newPromotionMedia.base_image,
                                  "onUpdate:baseImage": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newPromotionMedia.base_image) = $event)),
                                  "base-video": _ctx.newPromotionMedia.base_video,
                                  "onUpdate:baseVideo": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newPromotionMedia.base_video) = $event)),
                                  imageCroppable: ""
                                }, null, 8, ["file", "file_url", "type", "base-image", "base-video"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_9, [
                        (_ctx.newPromotion.basic.type != 'shoppable')
                          ? (_openBlock(), _createBlock(_component_app_file_attachment, {
                              key: 0,
                              class: "w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed cursor-pointer",
                              "onUpdate:modelValue": _ctx.handleFileSelected,
                              "onUpdate:localFileUrl": _ctx.handleFileURLReady,
                              "is-wrapper": "",
                              accept: 'image/jpeg,image/gif,image/png,video/mp4'
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                    default: _withCtx(() => _cache[32] || (_cache[32] = [
                                      _createTextVNode(" Choose media (image/video) ")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            }, 8, ["onUpdate:modelValue", "onUpdate:localFileUrl"]))
                          : _createCommentVNode("", true),
                        (_ctx.newPromotion.basic.type == 'shoppable')
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed cursor-pointer",
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showAddShoplist()))
                            }, [
                              _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-200" }, {
                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                    _createTextVNode(" Choose offer or product ")
                                  ])),
                                  _: 1
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_app_text_field, {
                          "has-title": true,
                          placeholder: 'Enter title',
                          padding: "px-3 py-2",
                          name: "Advert title",
                          rules: [_ctx.FormValidations.RequiredRule],
                          modelValue: _ctx.newPromotion.basic.name,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newPromotion.basic.name) = $event)),
                          customClass: 'bg-grey-50',
                          "update-value": _ctx.newPromotion.basic.name,
                          required: ""
                        }, {
                          title: _withCtx(() => _cache[34] || (_cache[34] = [
                            _createTextVNode(" Advert title ")
                          ])),
                          _: 1
                        }, 8, ["rules", "modelValue", "update-value"])
                      ]),
                      (_ctx.newPromotion.basic.type == 'website_visit')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_component_app_select, {
                                "has-title": true,
                                options: _ctx.ctaOptions,
                                paddings: "px-3 py-2",
                                modelValue: _ctx.newPromotion.basic.cta,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.newPromotion.basic.cta) = $event)),
                                autoComplete: "",
                                placeholder: 'Select CTA',
                                customClass: '!bg-grey-50',
                                theme: _ctx.Logic.Common.currentAppTheme()
                              }, {
                                title: _withCtx(() => _cache[35] || (_cache[35] = [
                                  _createTextVNode(" Call To Action (CTA) ")
                                ])),
                                _: 1
                              }, 8, ["options", "modelValue", "theme"])
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_app_text_field, {
                                "has-title": true,
                                placeholder: 'https://example.com',
                                padding: "px-3 py-2",
                                name: "Landing Page URL",
                                rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.WebsiteRule],
                                modelValue: _ctx.newPromotion.basic.landing_page_url,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newPromotion.basic.landing_page_url) = $event)),
                                customClass: '!bg-grey-50'
                              }, {
                                title: _withCtx(() => _cache[36] || (_cache[36] = [
                                  _createTextVNode(" Landing Page URL ")
                                ])),
                                _: 1
                              }, 8, ["rules", "modelValue"])
                            ])
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.newPromotion.basic.type == 'polls')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _createElementVNode("div", _hoisted_15, [
                              _createVNode(_component_app_text_field, {
                                "has-title": true,
                                placeholder: 'Enter question',
                                padding: "px-3 py-2",
                                name: "Question",
                                "is-textarea": "",
                                rules: [_ctx.FormValidations.RequiredRule],
                                "max-character": 50,
                                textAreaRow: '1',
                                modelValue: _ctx.newPromotion.basic.polls.question,
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newPromotion.basic.polls.question) = $event)),
                                customClass: '!bg-grey-50'
                              }, {
                                title: _withCtx(() => _cache[37] || (_cache[37] = [
                                  _createTextVNode(" Question ")
                                ])),
                                _: 1
                              }, 8, ["rules", "modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("div", _hoisted_17, [
                                _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                                    _createTextVNode(" Options ")
                                  ])),
                                  _: 1
                                }),
                                (_ctx.newPromotion.basic.polls.options.length < 4)
                                  ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                      key: 0,
                                      class: "!text-grey-500 dark:!text-grey-400",
                                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.addOption()))
                                    }, {
                                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                                        _createElementVNode("span", { class: "!text-primary-200" }, "+ Add New", -1)
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newPromotion.basic.polls.options, (item, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "w-full flex flex-row justify-between items-center border-b-[1px] border-gray-100 py-2 pr-2"
                                  }, [
                                    _createElementVNode("div", _hoisted_19, [
                                      _createVNode(_component_app_file_attachment, {
                                        isWrapper: "",
                                        accept: 'image/jpeg, image/png, image/gif',
                                        "local-file-url": item.image_url,
                                        "onUpdate:localFileUrl": ($event: any) => ((item.image_url) = $event),
                                        "model-value": item.image_blob,
                                        "onUpdate:modelValue": ($event: any) => ((item.image_blob) = $event),
                                        class: "!w-auto"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_app_image_loader, {
                                            "photo-url": item.image_url,
                                            class: "h-[40px] w-[40px] rounded-[7px] bg-gray-100 flex items-center justify-center"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_app_icon, {
                                                name: 'upload-image',
                                                class: "!h-[13px]"
                                              })
                                            ]),
                                            _: 2
                                          }, 1032, ["photo-url"])
                                        ]),
                                        _: 2
                                      }, 1032, ["local-file-url", "onUpdate:localFileUrl", "model-value", "onUpdate:modelValue"]),
                                      _createVNode(_component_app_content_editable, {
                                        defaultValue: item.value,
                                        modelValue: item.value,
                                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                        onContentChanged: ($event: any) => (
                          item.slug = _ctx.Logic.Common.convertToSlug(item.value)
                        ),
                                        class: "dark:!text-white !text-black !text-xs !outline-none focus:outline-none min-w-[40px] !whitespace-nowrap !bg-grey-100 dark:!bg-gray-900 px-2"
                                      }, null, 8, ["defaultValue", "modelValue", "onUpdate:modelValue", "onContentChanged"])
                                    ]),
                                    _createElementVNode("div", _hoisted_20, [
                                      (item.image_url)
                                        ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                            key: 0,
                                            class: "!text-red-500 !text-[11px] px-2 py-[2px] bg-red-50 rounded-[2px]",
                                            onClick: ($event: any) => (item.image_url = '')
                                          }, {
                                            default: _withCtx(() => _cache[40] || (_cache[40] = [
                                              _createTextVNode(" Remove Image ")
                                            ])),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.newPromotion.basic.polls.options.length > 2)
                                        ? (_openBlock(), _createBlock(_component_app_icon, {
                                            key: 1,
                                            name: 'cross-black',
                                            customClass: 'h-[10px]',
                                            onClick: ($event: any) => (_ctx.removeOption(index))
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ])
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.newPromotion.basic.type == 'banner')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createVNode(_component_app_text_field, {
                              "has-title": true,
                              placeholder: 'https://example.com',
                              padding: "px-3 py-2",
                              name: "Landing Page URL",
                              rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.WebsiteRule],
                              modelValue: _ctx.newPromotion.basic.landing_page_url,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newPromotion.basic.landing_page_url) = $event)),
                              customClass: '!bg-grey-50'
                            }, {
                              title: _withCtx(() => _cache[41] || (_cache[41] = [
                                _createTextVNode(" Landing Page URL ")
                              ])),
                              _: 1
                            }, 8, ["rules", "modelValue"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.promotionCreationStep.current == 2)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_22, [
                        _createVNode(_component_app_select, {
                          "has-title": true,
                          options: _ctx.ageRangeOptions,
                          paddings: "px-3 py-3",
                          modelValue: _ctx.newPromotion.targeting.age_ranges,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newPromotion.targeting.age_ranges) = $event)),
                          isMultiple: true,
                          autoComplete: "",
                          placeholder: 'Select age ranges',
                          customClass: '!bg-grey-50',
                          theme: _ctx.Logic.Common.currentAppTheme(),
                          required: ""
                        }, {
                          title: _withCtx(() => _cache[42] || (_cache[42] = [
                            _createTextVNode(" Age ranges ")
                          ])),
                          _: 1
                        }, 8, ["options", "modelValue", "theme"])
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createVNode(_component_app_select, {
                          "has-title": true,
                          options: _ctx.genderOptions,
                          paddings: "px-3 py-3",
                          modelValue: _ctx.newPromotion.targeting.gender,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.newPromotion.targeting.gender) = $event)),
                          placeholder: 'Select gender',
                          customClass: 'bg-grey-50',
                          theme: _ctx.Logic.Common.currentAppTheme(),
                          required: ""
                        }, {
                          title: _withCtx(() => _cache[43] || (_cache[43] = [
                            _createTextVNode(" Gender ")
                          ])),
                          _: 1
                        }, 8, ["options", "modelValue", "theme"])
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_app_select, {
                          "has-title": true,
                          options: _ctx.locationOptions,
                          paddings: "px-3 py-3",
                          modelValue: _ctx.newPromotion.targeting.locations,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.newPromotion.targeting.locations) = $event)),
                          isMultiple: true,
                          autoComplete: "",
                          placeholder: 'Select locations',
                          customClass: 'bg-grey-50',
                          ref: "locationSelect",
                          theme: _ctx.Logic.Common.currentAppTheme(),
                          required: ""
                        }, {
                          title: _withCtx(() => _cache[44] || (_cache[44] = [
                            _createTextVNode(" Locations ")
                          ])),
                          _: 1
                        }, 8, ["options", "modelValue", "theme"])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_app_select, {
                          "has-title": true,
                          options: _ctx.behaviouralDemographiesOptions,
                          paddings: "px-3 py-3",
                          modelValue: _ctx.newPromotion.targeting.behavioural_demographies,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.newPromotion.targeting.behavioural_demographies) = $event)),
                          isMultiple: true,
                          autoComplete: "",
                          placeholder: 'Choose behavioural demographies',
                          customClass: '!bg-grey-50',
                          ref: "behaviouralDemographySelect",
                          theme: _ctx.Logic.Common.currentAppTheme(),
                          required: ""
                        }, {
                          title: _withCtx(() => _cache[45] || (_cache[45] = [
                            _createTextVNode(" Focus demographies ")
                          ])),
                          _: 1
                        }, 8, ["options", "modelValue", "theme"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.promotionCreationStep.current == 3)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_app_text_field, {
                          "has-title": true,
                          placeholder: 'Enter Ad budget',
                          padding: "px-3 py-2",
                          name: "Total Ad Budget",
                          "is-formatted": "",
                          type: "tel",
                          rules: [
                  _ctx.FormValidations.customValidator(
                    parseInt(_ctx.newPromotion.budgeting.total.replace(/,/g, '')) > 4999,
                    'Budget must be greater than or equals to ₦5,000'
                  ),
                ],
                          modelValue: _ctx.newPromotion.budgeting.total,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.newPromotion.budgeting.total) = $event)),
                          customClass: '!bg-grey-50',
                          required: ""
                        }, {
                          "inner-prefix": _withCtx(() => [
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => _cache[46] || (_cache[46] = [
                                _createTextVNode(" ₦ ")
                              ])),
                              _: 1
                            })
                          ]),
                          title: _withCtx(() => _cache[47] || (_cache[47] = [
                            _createTextVNode("Ad budget ")
                          ])),
                          _: 1
                        }, 8, ["rules", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_app_text_field, {
                          "has-title": true,
                          placeholder: `Daily limit`,
                          padding: "px-3 py-2",
                          name: `Set daily limit`,
                          "is-formatted": "",
                          type: "tel",
                          rules: [
                  _ctx.FormValidations.customValidator(
                    parseInt(_ctx.newPromotion.budgeting.max_amount.replace(/,/g, '')) > 4999,
                    'Daily limit must be greater than or equals to ₦5,000'
                  ),
                ],
                          modelValue: _ctx.newPromotion.budgeting.max_amount,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.newPromotion.budgeting.max_amount) = $event)),
                          customClass: '!bg-grey-50',
                          required: ""
                        }, {
                          title: _withCtx(() => _cache[48] || (_cache[48] = [
                            _createTextVNode(" Daily limit ")
                          ])),
                          "inner-prefix": _withCtx(() => [
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => _cache[49] || (_cache[49] = [
                                _createTextVNode(" ₦ ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["rules", "modelValue"])
                      ]),
                      _createVNode(_component_app_text_field, {
                        "has-title": true,
                        placeholder: 'Start date',
                        padding: "px-3 py-2",
                        name: "Start date",
                        type: "date",
                        "prevent-back-date": "",
                        "miminum-date": new Date().toString(),
                        rules: [_ctx.FormValidations.RequiredRule],
                        modelValue: _ctx.newPromotion.scheduling.start,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.newPromotion.scheduling.start) = $event)),
                        customClass: '!bg-grey-50',
                        required: ""
                      }, {
                        title: _withCtx(() => _cache[50] || (_cache[50] = [
                          _createTextVNode(" Start date ")
                        ])),
                        _: 1
                      }, 8, ["miminum-date", "rules", "modelValue"]),
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_app_checkbox, {
                          standard: true,
                          modelValue: _ctx.newPromotion.run_until_paused,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.newPromotion.run_until_paused) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => _cache[51] || (_cache[51] = [
                                _createTextVNode(" Run until paused ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      (!_ctx.newPromotion.run_until_paused)
                        ? (_openBlock(), _createBlock(_component_app_text_field, {
                            key: 0,
                            "has-title": true,
                            placeholder: 'End date',
                            padding: "px-3 py-2",
                            name: "End date",
                            type: "date",
                            "prevent-back-date": "",
                            "miminum-date": new Date(_ctx.newPromotion.scheduling.start).toString(),
                            rules: [_ctx.FormValidations.RequiredRule],
                            modelValue: _ctx.newPromotion.scheduling.end,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.newPromotion.scheduling.end) = $event)),
                            customClass: '!bg-grey-50',
                            required: ""
                          }, {
                            title: _withCtx(() => _cache[52] || (_cache[52] = [
                              _createTextVNode(" End date ")
                            ])),
                            _: 1
                          }, 8, ["miminum-date", "rules", "modelValue"]))
                        : _createCommentVNode("", true),
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _createElementVNode("div", _hoisted_30, [
                              _createVNode(_component_app_select, {
                                "has-title": true,
                                options: _ctx.timeOfTheDayOptions,
                                paddings: "px-3 py-2",
                                modelValue: _ctx.newPromotion.scheduling.ad_start_time,
                                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.newPromotion.scheduling.ad_start_time) = $event)),
                                placeholder: 'Start time',
                                customClass: '!bg-grey-50',
                                theme: _ctx.Logic.Common.currentAppTheme(),
                                required: ""
                              }, {
                                title: _withCtx(() => _cache[53] || (_cache[53] = [
                                  _createTextVNode(" Ad Start Time ")
                                ])),
                                _: 1
                              }, 8, ["options", "modelValue", "theme"])
                            ]),
                            _createElementVNode("div", _hoisted_31, [
                              _createVNode(_component_app_select, {
                                "has-title": true,
                                options: _ctx.timeOfTheDayOptions,
                                paddings: "px-3 py-2",
                                placeholder: 'End time',
                                modelValue: _ctx.newPromotion.scheduling.ad_end_time,
                                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.newPromotion.scheduling.ad_end_time) = $event)),
                                customClass: '!bg-grey-50',
                                theme: 'dark',
                                required: ""
                              }, {
                                title: _withCtx(() => _cache[54] || (_cache[54] = [
                                  _createTextVNode(" Ad End Time ")
                                ])),
                                _: 1
                              }, 8, ["options", "modelValue"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.endTimeMustBeMoreThanStartTime())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _createVNode(_component_app_normal_text, { class: "!text-error-main !text-left" }, {
                              default: _withCtx(() => _cache[55] || (_cache[55] = [
                                _createTextVNode(" End time must be more than start time ")
                              ])),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.promotionCreationStep.current == 4)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createElementVNode("div", _hoisted_33, [
                        _createVNode(_component_app_checkbox, {
                          standard: true,
                          modelValue: _ctx.newPromotion.legal.merchant_terms,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.newPromotion.legal.merchant_terms) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => _cache[56] || (_cache[56] = [
                                _createTextVNode(" I have read and agree to the Shoppoint Merchant Platform’s "),
                                _createElementVNode("span", { class: "!text-primary-main dark:!text-primary-200 underline" }, "Terms and Conditions", -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_app_checkbox, {
                          standard: true,
                          modelValue: _ctx.newPromotion.legal.privacy,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.newPromotion.legal.privacy) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => _cache[57] || (_cache[57] = [
                                _createTextVNode(" I understand and agree to the "),
                                _createElementVNode("span", { class: "!text-primary-main dark:!text-primary-200 underline" }, "Privacy Policy", -1),
                                _createTextVNode(" regarding user data collected through my promotion ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createVNode(_component_app_checkbox, {
                          standard: true,
                          modelValue: _ctx.newPromotion.legal.ad_guidelines,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.newPromotion.legal.ad_guidelines) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => _cache[58] || (_cache[58] = [
                                _createTextVNode(" I confirm that my promotion complies with Shoppoint’s "),
                                _createElementVNode("span", { class: "!text-primary-main dark:!text-primary-200 underline" }, "Advertising Guidelines", -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["parentRefs"]),
            _cache[59] || (_cache[59] = _createElementVNode("div", { class: "h-[70px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, {
            "custom-class": "pt-4",
            class: "!z-[20000]"
          }, {
            default: _withCtx(() => [
              (!_ctx.newPromotion.progress.loading)
                ? (_openBlock(), _createBlock(_component_app_button, {
                    key: 0,
                    onClick: _withModifiers(_ctx.continueToNext, ["prevent"]),
                    class: _normalizeClass(`w-full ${_ctx.formIsValid ? '' : 'opacity-50'}`),
                    padding: 'py-3'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isEdit
                ? "Update"
                : _ctx.promotionCreationStep.current == _ctx.promotionCreationStep.total
                ? "Submit for review"
                : "Continue"), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "class"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _createVNode(_component_app_normal_text, { class: "!text-grey-700 dark:!text-gray-400" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.newPromotion.progress.message), 1)
                        ]),
                        _: 1
                      }),
                      (_openBlock(), _createElementBlock("svg", _hoisted_38, _cache[60] || (_cache[60] = [
                        _createElementVNode("circle", {
                          class: "opacity-25",
                          cx: "12",
                          cy: "12",
                          r: "10",
                          stroke: "currentColor",
                          "stroke-width": "4"
                        }, null, -1),
                        _createElementVNode("path", {
                          class: "opacity-75",
                          fill: "currentColor",
                          d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        }, null, -1)
                      ])))
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", {
                        class: "absolute h-full top-0 left-0 rounded-[6px] bg-secondary-500",
                        style: _normalizeStyle(`width: ${_ctx.newPromotion.progress.percentage}%`)
                      }, null, 4)
                    ])
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (!_ctx.isEdit)
          ? {
              name: "extra-topbar",
              fn: _withCtx(() => [
                _createVNode(_component_app_normal_text, { "custom-class": '!text-primary-400 dark:!text-primary-300' }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString("Step " +
            `(${_ctx.promotionCreationStep.current + "/" + _ctx.promotionCreationStep.total})`), 1)
                  ]),
                  _: 1
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["title", "goBackHandler"])
    ]),
    _: 1
  }))
}