<template>
  <div
    :class="`flex flex-row items-center py-0 z-[400] w-full justify-between !font-inter px-4 ${topBarStyle}`"
    style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
  >
    <div class="flex flex-row space-x-2">
      <app-image-loader
        :photoUrl="
          Logic.Common.currentAccountType() == 'merchant'
            ? Logic.Auth.GetCurrentBusiness()?.photo_url || ''
              ? Logic.Auth.GetCurrentBusiness()?.photo_url || ''
              : '/images/temp/vendor.svg'
            : Logic.Auth.AuthUser?.profile?.photo_url
            ? Logic.Auth.AuthUser?.profile?.photo_url
            : '/images/avatar-1.png'
        "
        :customClass="'!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200'"
        @click="openSideBar()"
      />
      <div class="flex flex-col justify-start" v-if="type == 'full'">
        <app-normal-text
          class="!text-[11px] !text-grey-700 dark:!text-gray-200 !text-left"
        >
          {{ greetings }}
        </app-normal-text>
        <app-header-text class="!text-sm !line-clamp-1 !text-left">
          {{ Logic.Auth.AuthUser?.first_name }}
        </app-header-text>
      </div>
    </div>

    <app-header-text v-if="type != 'full'">
      {{ title }}
    </app-header-text>

    <div class="flex flex-row space-x-3 items-center">
      <template v-if="Logic.Common.currentAccountType() == 'client'">
        <app-icon
          v-if="hasSearch"
          :name="'search-circle'"
          :customClass="'!h-[38px]'"
        />
        <app-icon
          v-if="hasNotify"
          :name="'notify-circle'"
          :customClass="'!h-[38px] cursor-pointer'"
          @click="Logic.Common.GoToRoute('/others/notifications')"
        />
        <app-icon
          v-if="hasCart"
          :name="'cart-circle'"
          :customClass="'!h-[38px]'"
        />

        <app-icon
          @click="showStory()"
          v-if="hasStory"
          :name="`story-circle${
            Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`"
          :customClass="'!h-[38px] cursor-pointer'"
        />
        <app-icon
          @click="Logic.Common.GoToRoute('/basket')"
          v-if="hasShoppingBasket"
          :name="`shopping-basket-circle${
            Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`"
          :customClass="'!h-[38px] cursor-pointer '"
        />
      </template>
      <template v-else>
        <app-icon
          v-if="hasNotify || !hasAddButton"
          :name="`notify-circle${
            Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`"
          :customClass="'!h-[38px] cursor-pointer'"
          @click="Logic.Common.GoToRoute('/others/notifications')"
        />
        <app-icon
          v-if="hasAddButton"
          :name="`add-circle${
            Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`"
          :customClass="'!h-[38px] cursor-pointer'"
          @click="addButtonAction()"
        />
      </template>

      <slot name="extra-top-bar-right" />
    </div>
  </div>
</template>
<script lang="ts">
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { defineComponent } from "vue";
import AppImageLoader from "../AppImageLoader";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppImageLoader,
    AppHeaderText,
  },
  props: {
    type: {
      type: String,
      default: "full",
    },
    title: {
      type: String,
      default: "",
    },
    hasNotify: {
      type: Boolean,
      default: false,
    },
    hasCart: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    hasStory: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: false,
    },
    addButtonAction: {
      type: Function,
      default: () => {},
    },
    hasShoppingBasket: {
      type: Boolean,
      default: true,
    },
    openSideBar: {
      type: Function,
      default: () => {},
    },
    showStory: {
      type: Function,
      default: () => {},
    },
    greetings: {
      type: String,
      default: "Welcome",
    },
    topBarStyle: {
      type: String,
      default: "!bg-white dark:!bg-black",
    },
  },
  name: "AppTopBar",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
