<template>
  <app-wrapper>
    <subpage-layout
      :title="isEdit ? ' Update Offer' : pageTitle"
      :goBackHandler="goBackHandler"
    >
      <template v-slot:extra-topbar v-if="!isEdit">
        <app-normal-text :custom-class="'!text-primary-400 dark:!text-primary-300'"
          >{{
            "Step " + `(${offerCreationStep.current + "/" + offerCreationStep.total})`
          }}
        </app-normal-text>
      </template>
      <template v-slot:extra-topbar-row>
        <div class="w-full flex flex-col pl-4 pb-2" v-if="isEdit">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 pr-4">
              <div
                v-for="(item, index) in sections"
                :key="index"
                :class="`px-4 py-1 ${
                  selectedSection == item.id ? 'bg-primary-main' : 'bg-grey-50'
                }  rounded-[20px]  flex flex-row items-center justify-center cursor-pointer`"
                @click="selectedSection = item.id"
              >
                <app-normal-text
                  :class="`${
                    selectedSection == item.id ? '!text-white' : '!text-grey-700'
                  }`"
                  :custom-class="'whitespace-nowrap'"
                >
                  {{ item.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="`${
            offerCreationStep.current == offerCreationStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`"
          v-else
        >
          <div
            v-for="(item, index) in offerCreationStep.total"
            :key="item"
            :class="`${
              offerCreationStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == offerCreationStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`"
          ></div>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-4 px-4 pt-3">
        <app-form-wrapper
          ref="formComp"
          :parentRefs="parentRefs"
          class="w-full flex flex-col space-y-4 items-center justify-center"
        >
          <!-- Offer Media -->
          <template v-if="selectedSection == 'offer_media'">
            <div
              class="w-full mdlg:!w-[480px] lg:!w-[480px] h-[calc(100vh-160px)] relative"
              v-if="(!isEdit && newShoplist.file) || (isEdit && singleStory)"
            >
              <component
                class="w-full h-full border-[1px] border-grey-200 dark:!border-grey-700 !rounded-[10px]"
                :is="componentBase"
                :file="newShoplist.file"
                :file_url="newShoplist.file_url"
                :type="newShoplist.type"
                ref="mediaEditorRef"
                v-model:base-image="newShoplist.base_image"
                v-model:base-video="newShoplist.base_video"
                :data="[singleStory]"
                :showSettings="showSettings"
                :hideExtraShoplistInfo="newShoplist.instagram_data != undefined"
                :has-edit="true"
                :isFullScreen="false"
                :listenToSpaceBar="false"
              >
              </component>
            </div>

            <div class="flex flex-col w-full">
              <app-file-attachment
                class="w-full h-[50px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed"
                @update:modelValue="handleFileSelected"
                @update:localFileUrl="handleFileURLReady"
                is-wrapper
                :accept="'image/jpeg,image/gif,image/png,video/mp4'"
              >
                <template v-slot:content>
                  <div class="w-full flex flex-row space-x-2 justify-center items-center">
                    <app-normal-text class="!text-gray-700 dark:!text-gray-200">
                      Choose media (image/video)
                    </app-normal-text>
                  </div>
                </template>
              </app-file-attachment>
            </div>
          </template>

          <template v-if="selectedSection == 'offer_details'">
            <div class="relative h-auto w-full py-3 flex flex-col space-y-2">
              <div class="w-full flex flex-col space-y-2">
                <div class="w-full grid grid-cols-2 gap-2">
                  <div class="col-span-1 flex flex-col">
                    <app-text-field
                      :has-title="true"
                      :placeholder="'Enter Title'"
                      type="text"
                      padding="px-3 py-2"
                      :name="'Title'"
                      :custom-class="'!bg-grey-50'"
                      :rules="[FormValidations.RequiredRule]"
                      v-model="newShoplist.title"
                    >
                      <template v-slot:title> Offer Title </template>
                    </app-text-field>
                  </div>
                  <div class="col-span-1 flex flex-col z-20">
                    <app-text-field
                      :has-title="true"
                      type="date"
                      :placeholder="'Select Date'"
                      :custom-class="'!bg-grey-50'"
                      padding="px-3 py-2"
                      :prevent-back-date="true"
                      :miminum-date="new Date().toString()"
                      :rules="[FormValidations.RequiredRule]"
                      v-model="newShoplist.expires_on"
                    >
                      <template v-slot:title> Valid Till </template>
                    </app-text-field>
                  </div>
                </div>

                <div class="w-full flex flex-col space-y-1 pt-1">
                  <app-normal-text
                    class="w-full text-left !font-semibold !text-gray-700 dark:!text-gray-400"
                  >
                    Products
                  </app-normal-text>
                  <div class="w-full grid grid-cols-3 gap-3">
                    <div
                      class="flex flex-col space-y-1"
                      v-for="(item, index) in newShoplist.products"
                      :key="index"
                    >
                      <app-image-loader
                        :photoUrl="item.image_url"
                        class="!h-[90px] col-span-1 relative rounded-[10px] border-[1px] border-grey-500"
                      >
                        <span
                          class="absolute top-[-5px] right-[-5px] h-[20px] w-[20px] flex justify-center items-center bg-grey-200 dark:!bg-grey-white rounded-full cursor-pointer"
                          @click="removeProduct(index)"
                        >
                          <app-icon :name="'close'" :customClass="'!h-[10px]'" />
                        </span>
                      </app-image-loader>
                      <app-normal-text class="!text-left">
                        {{ Logic.Common.convertToMoney(item.price, false, "ngn") }}
                      </app-normal-text>
                    </div>

                    <div
                      @click="showAddProduct()"
                      class="!h-[90px] col-span-1 rounded-[10px] border-[1px] border-grey-500 border-dashed flex items-center justify-center cursor-pointer"
                    >
                      <app-icon :name="'plus-grey'" class="!h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Cashback Reward -->
              <div class="w-full flex flex-col pt-2 space-y-2">
                <template v-if="!canSetReward">
                  <div class="w-full flex flex-row rounded-[8px] bg-primary-50 px-3 py-3">
                    <app-normal-text
                      class="!text-primary-500 dark:!text-primary-500 !text-left"
                    >
                      This offer has other merchant products. You won't be able to
                      customize your offer once you add an affiliate product.
                    </app-normal-text>
                  </div>
                </template>
                <div
                  class="w-full flex flex-col"
                  :class="`${!canSetReward ? 'opacity-50' : ''}`"
                >
                  <AppTextField
                    :focus-border="'border-primary-400'"
                    :has-title="true"
                    :placeholder="'Enter percentage'"
                    name="Cashback Reward"
                    :rules="[FormValidations.RequiredRule]"
                    :required="true"
                    type="tel"
                    :disabled="!canSetReward"
                    v-model="newShoplist.reward_percentage"
                    :update-value="newShoplist.reward_percentage"
                  >
                    <template v-slot:title>Cashback Reward </template>
                    <template v-slot:inner-suffix>
                      <span class="pl-1">
                        <app-normal-text :color="'text-grey-700'">%</app-normal-text>
                      </span>
                    </template>
                  </AppTextField>
                </div>
              </div>

              <!-- Category -->
              <div class="w-full flex flex-col space-y-2 pt-1" v-if="false">
                <app-select
                  :options="categoryOptions"
                  :has-title="true"
                  :placeholder="'Select Category'"
                  :custom-class="'!bg-grey-50'"
                  :paddings="'px-4 py-3'"
                  auto-complete
                  v-model="newShoplist.shipping_category_id"
                  :theme="Logic.Common.currentAppTheme()"
                  required
                >
                  <template v-slot:title> Category </template>
                </app-select>

                <app-select
                  :has-title="true"
                  :placeholder-color="'placeholder:text-black'"
                  :default-size="'w-full'"
                  :placeholder="'Select'"
                  :custom-class="'!bg-grey-50'"
                  :paddings="'px-4 py-3'"
                  :ref="'product_size'"
                  :theme="Logic.Common.currentAppTheme()"
                  :options="packageDimensionOptions"
                  v-model="newShoplist.package_dimension_id"
                  required
                >
                  <template v-slot:title> Package Dimension </template>
                </app-select>
              </div>

              <div class="w-full flex flex-col space-y-2 pt-1">
                <app-text-field
                  isTextarea
                  :hasTitle="true"
                  placeholder="Enter description"
                  text-area-row="4"
                  type="text"
                  :maxCharacter="250"
                  :custom-class="'!bg-grey-50'"
                  name="Description"
                  :rules="[FormValidations.RequiredRule]"
                  v-model="newShoplist.description"
                >
                  <template v-slot:title> Description </template>
                </app-text-field>
              </div>
            </div>
          </template>
        </app-form-wrapper>

        <!-- Spacer -->
        <div class="h-[70px]"></div>
      </div>

      <fixed-container custom-class="pt-4" class="!z-[20000]">
        <template v-if="!newShoplist.progress.loading">
          <app-button
            @click.prevent="continueToNext"
            :class="`w-full ${formIsValid ? '' : 'opacity-50'}`"
            :padding="'py-3'"
          >
            {{
              isEdit
                ? "Update"
                : offerCreationStep.current == offerCreationStep.total
                ? "Submit for review"
                : "Continue"
            }}
          </app-button>
        </template>
        <div v-else class="w-full flex flex-col space-y-4">
          <div class="w-full flex flex-row justify-between items-center">
            <app-normal-text class="!text-grey-700 dark:!text-gray-400">
              {{ newShoplist.progress.message }}
            </app-normal-text>
            <svg
              :class="`animate-spin  h-5 w-5 text-primary-400`"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <div class="w-full relative h-[8px] rounded-[6px] bg-grey-50">
            <div
              class="absolute h-full top-0 left-0 rounded-[6px] bg-secondary-500"
              :style="`width: ${newShoplist.progress.percentage}%`"
            ></div>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  shallowRef,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewDidLeave, onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { newShoplist } from "@shpt/ui-components/src/composable/shop";
import {
  AppIcon,
  AppTextField,
  AppNormalText,
  AppImageLoader,
  AppButton,
  AppStoryView,
  AppFileAttachment,
  AppSelect,
  AppFormWrapper,
} from "@shpt/ui-components";
import { MediaBox, SelectOption, Story } from "@shpt/ui-components/src/types";
import {
  FormValidations,
  getMediaBox,
  setAppTheme,
} from "@shpt/ui-components/src/composable";
import MediaEditor from "@shpt/ui-components/src/components/AppCommon/MediaEditor.vue";
import { showSettings } from "@shpt/ui-components/src/composable/common";
import AppWrapper from "@/components/AppWrapper.vue";
import { UpdateShoplistCollectionType } from "@shpt/logic/src/gql/graphql";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppTextField,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppFileAttachment,
    AppSelect,
    AppWrapper,
    AppFormWrapper,
    MediaEditor,
    FixedContainer,
  },
  name: "CreateShoplistPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "SingleShoplistCollection",
        method: "GetShoplistCollection",
        params: [],
        useRouteQuery: true,
        queries: ["uuid"],
        requireAuth: true,
        condition: {
          routeSearchItem: "query",
          searchQuery: "uuid",
        },
      },
    ],
    tracking_data: {
      lable: "Create Shoplist Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Create Shoplist",
    });

    const selectedSection = ref("offer_media");
    const canSetReward = ref(true);

    const offerCreationStep = reactive({
      current: 1,
      total: 2,
    });

    const pageTitle = ref("Offer Media");

    const sections = reactive([
      {
        id: "offer_media",
        title: "Offer Media",
      },
      {
        id: "offer_details",
        title: "Offer Details",
      },
    ]);

    const formComp = ref();

    const mediaEditorRef = ref();

    const SingleShoplistCollection = ref(Logic.Shoplist.SingleShoplistCollection);
    const ManyShippingCategory = ref(Logic.Shoplist.ManyShippingCategory);
    const ManyPackageDimensions = ref(Logic.Shoplist.ManyPackageDimensions);
    const packageDimensionOptions = reactive<SelectOption[]>([]);

    const singleStory = ref<Story>();

    const isEdit = ref(false);
    const componentBase = shallowRef();

    const shoplistPurchaseType = reactive<SelectOption[]>([
      {
        key: "direct_purchase",
        value: "Direct Purchase",
      },
      {
        key: "group_buy",
        value: "Group Buy",
      },
    ]);

    const categoryOptions = reactive<SelectOption[]>([]);

    const setRewardSettingStatus = () => {
      const currentBusiness = Logic.Auth.GetCurrentBusiness();
      if (currentBusiness) {
        const productOutsideBusiness = newShoplist.products.filter(
          (item: any) => item.business_id != parseInt(currentBusiness.id)
        );

        if (productOutsideBusiness.length > 0) {
          canSetReward.value = false;
        } else {
          canSetReward.value = true;
        }
      }
    };

    const showAddProduct = () => {
      Logic.Common.showModal({
        show: true,
        type: "add_products",
        title: "Select products to add",
        action: (data: any) => {
          newShoplist.products = data;
          Logic.Common.showModal({ show: false });
          //  Check if all product is from current business
          setRewardSettingStatus();
        },
        extraData: {
          selected: newShoplist.products,
        },
      });
    };

    const formIsValid = computed(() => {
      const hasMedia = newShoplist.file || singleStory.value?.media;

      const hasDetails =
        newShoplist.title != "" &&
        newShoplist.description != "" &&
        newShoplist.products.length > 0 &&
        ((canSetReward.value && newShoplist.reward_percentage != "") ||
          !canSetReward.value);

      if (isEdit.value) {
        return hasDetails && hasMedia;
      } else {
        if (offerCreationStep.current == 1) {
          return hasMedia;
        } else {
          return hasDetails;
        }
      }
    });

    const handleProgress = (progress: number) => {
      newShoplist.progress.percentage = progress;
      newShoplist.progress.message = "Uploading...";
      if (progress >= 100) {
        newShoplist.progress.message = "Processing...";
      }
    };

    const resetForm = () => {
      newShoplist.base_image = undefined;
      newShoplist.base_video = undefined;
      newShoplist.reward_percentage = "";
      newShoplist.description = "";
      newShoplist.expires_on = Logic.Common.momentInstance(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD");

      newShoplist.products = [];
      newShoplist.progress = {
        loading: false,
        message: "",
        percentage: 0,
      };
      newShoplist.title = "";
      newShoplist.shipping_category_id = "";
      newShoplist.shipping_category_data = "";
      newShoplist.package_dimension_id = "";
      newShoplist.file = undefined;
      newShoplist.package_dimension_data = "";
    };

    const saveShoplist = () => {
      if (formIsValid.value) {
        let baseMedia: Blob | undefined = newShoplist.base_image;

        if (!canSetReward.value) {
          newShoplist.reward_percentage = "0";
        }

        if (!isEdit.value) {
          Logic.Shoplist.CreateShoplistCollectionForm = {
            base_image: baseMedia,
            expires_at: newShoplist.expires_on,
            media_type: newShoplist.type,
            name: newShoplist.title,
            // base_image_url: newShoplist.instagram_data?.media_thumbnail,
            // base_video_url: newShoplist.instagram_data?.media_url,
            products: newShoplist.products.map((item) => item.id),
            reward_percentage: newShoplist.reward_percentage,
            description: newShoplist.description || "",
            type: UpdateShoplistCollectionType.Business,
            sub_business_id: Logic.Auth.AuthUser?.business_admin?.id
              ? parseInt(Logic.Auth.AuthUser?.business_admin?.id || "0")
              : undefined,
          };

          if (
            newShoplist.type == "video" &&
            Logic.Shoplist.CreateShoplistCollectionForm
          ) {
            Logic.Shoplist.CreateShoplistCollectionForm.collection_media =
              newShoplist.base_video;
          }

          newShoplist.progress.loading = true;

          Logic.Shoplist.CreateShoplistCollection(handleProgress)?.then((data) => {
            if (data) {
              Logic.Shoplist.GetShoplistCollections(
                1,
                15,
                "CREATED_AT",
                "DESC",
                Logic.Auth.CurrentBusinessIsSuperAdmin()
                  ? `{
            column: BUSINESS_PROFILE_ID
            operator: EQ
            value: ${Logic.Auth.GetCurrentBusiness()?.id}
            } 
            `
                  : `{
            column: BUSINESS_PROFILE_ID
            operator: EQ
            value: ${Logic.Auth.GetCurrentBusiness()?.id}
            AND: {
              column: SUB_BUSINESS_ID
              operator: EQ
              value: ${Logic.Auth.AuthUser?.business_admin?.id}
            }
            } `
              ).then(() => {
                newShoplist.progress.loading = false;
                resetForm();
                Logic.Common.showAlert({
                  show: true,
                  message: "Offer created successfully",
                  type: "success",
                });
              });
            } else {
              newShoplist.progress.loading = false;
            }
          });

          Logic.Common.showAlert({
            show: true,
            message: `Offer is now uploading...`,
            type: "info",
            wait_until_next_alert: true,
          });
          Logic.Common.goBack();
        } else {
          Logic.Shoplist.UpdateShoplistCollectionForm = {
            collection_id: parseInt(SingleShoplistCollection.value?.id || "0"),
            expires_at: newShoplist.expires_on,
            media_type: newShoplist.type,
            name: newShoplist.title,
            // base_image_url: newShoplist.instagram_data?.media_thumbnail,
            // base_video_url: newShoplist.instagram_data?.media_url,
            products: newShoplist.products.map((item) => item.id),
            reward_percentage: newShoplist.reward_percentage,
            description: newShoplist.description || "",
            type: UpdateShoplistCollectionType.Business,
            sub_business_id: Logic.Auth.AuthUser?.business_admin?.id
              ? parseInt(Logic.Auth.AuthUser?.business_admin?.id || "0")
              : undefined,
          };

          if (baseMedia) {
            Logic.Shoplist.UpdateShoplistCollectionForm.base_image = baseMedia;
          }
          if (newShoplist.type == "video") {
            if (newShoplist.base_video) {
              Logic.Shoplist.UpdateShoplistCollectionForm.collection_media =
                newShoplist.base_video;
            }
          }

          newShoplist.progress.loading = true;

          Logic.Shoplist.UpdateShoplistCollection(handleProgress)?.then((data) => {
            if (data) {
              Logic.Shoplist.GetShoplistCollection(
                SingleShoplistCollection.value?.uuid || ""
              ).then(() => {
                newShoplist.progress.loading = false;
                Logic.Common.showAlert({
                  show: true,
                  message: "Offer saved successfully",
                  type: "success",
                });
                resetForm();
              });
            } else {
              newShoplist.progress.loading = false;
            }
          });
          Logic.Common.showAlert({
            show: true,
            message: "Offer is saving...",
            type: "info",
            wait_until_next_alert: true,
          });
          Logic.Common.goBack();
        }
      }
    };

    const setStory = () => {
      if (SingleShoplistCollection.value) {
        const mediaBox = getMediaBox(SingleShoplistCollection.value);
        singleStory.value = {
          media: mediaBox,
          caption: "",
          is_group_buy: false,
          saved: false,
          saving: mediaBox.reward_percentage || 0,
          type: "shoplist",
          shoplist: {
            base_tag: mediaBox.main_category || "",
            price: mediaBox.price || 0,
            product_images: mediaBox.grid_images || [],
            title: mediaBox.title || "",
            uuid: SingleShoplistCollection.value.uuid,
            reward_percentage: mediaBox.reward_percentage || 0,
            reward_type: "cashback",
            type: "shoplist",
          },
        };
      }
    };

    const goBackHandler = () => {
      if (isEdit.value) {
        Logic.Common.goBack();
      } else {
        if (offerCreationStep.current <= 1) {
          Logic.Common.goBack();
        } else {
          offerCreationStep.current--;
        }
      }
    };

    const setDefault = () => {
      resetForm();
      if (SingleShoplistCollection.value) {
        newShoplist.title = SingleShoplistCollection.value.name;
        (newShoplist.expires_on = Logic.Common.momentInstance(
          SingleShoplistCollection.value.expires_at
        ).format("YYYY-MM-DD")),
          (newShoplist.description = SingleShoplistCollection.value.description || "");
        newShoplist.type =
          SingleShoplistCollection.value.media_type == "video" ? "video" : "image";
        newShoplist.products = SingleShoplistCollection.value.products.map((item) => {
          return {
            id: item.id,
            image_url: item.primary_image_url,
            title: item.name,
            price: parseFloat(item.regular_price || "0"),
            business_id: parseInt(item.business?.id || "0"),
          };
        });
        newShoplist.shipping_category_id =
          SingleShoplistCollection.value.shipping_category?.category_id || "";
        newShoplist.shipping_category_data =
          SingleShoplistCollection.value.shipping_category?.category || "";
        newShoplist.package_dimension_id =
          SingleShoplistCollection.value.dimension_size?.name || "";
        newShoplist.reward_percentage =
          SingleShoplistCollection.value.reward_percentage || "";

        setRewardSettingStatus();
      }
    };

    const handleFileURLReady = (url: string) => {
      newShoplist.file_url = url;
      mediaEditorRef.value?.runOnIonViewDidLeave();
      componentBase.value = MediaEditor;
      setTimeout(() => {
        mediaEditorRef.value?.runOnIonViewDidEnter();
      }, 600);
    };

    const handleFileSelected = (data: any) => {
      // Check file type from blob
      const fileType = data.type;
      if (fileType.includes("video")) {
        newShoplist.type = "video";
      } else {
        newShoplist.type = "image";
      }
      newShoplist.file = data;
    };

    const setShippingCategoryOptions = () => {
      categoryOptions.length = 0;
      ManyShippingCategory.value?.forEach((item: any) => {
        categoryOptions.push({
          key: item.category_id,
          value: item.category,
          extras: JSON.stringify(item),
        });
      });
    };

    const setPackageDimensions = () => {
      packageDimensionOptions.length = 0;

      ManyPackageDimensions.value?.forEach((item) => {
        packageDimensionOptions.push({
          key: item.name,
          value: item.name,
          imageUrl: item.description_image_url,
          extras: JSON.stringify(item),
        });
      });
    };

    const setStoryForInstagram = () => {
      const currentBusiness = Logic.Auth.GetCurrentBusiness();
      const images = newShoplist.products.map((item) => item.image_url);
      const mediaBox: MediaBox = {
        base_image: newShoplist.instagram_data?.media_thumbnail || "",
        uuid: newShoplist.instagram_data?.media_id || "",
        grid_images: images,
        tags: [],
        is_large_view: false,
        user_info: {
          image: currentBusiness?.photo_url || "",
          image_size: "h-10 w-10",
          handle: currentBusiness?.business_tag || "",
          name: currentBusiness?.business_name || "",
        },
        title: newShoplist.title,
        reward_percentage: 0,
        reward_type: "cashback",
        video_url: newShoplist.instagram_data?.media_url || "",
      };

      singleStory.value = {
        media: mediaBox,
        caption: newShoplist.title,
        is_group_buy: false,
        saved: false,
        saving: 0,
        type: "shoplist",
        shoplist: {
          base_tag: "",
          price: newShoplist.products.reduce((acc, curr) => acc + curr.price, 0),
          product_images: images,
          title: newShoplist.title || "New Shoplist",
          uuid: newShoplist.instagram_data?.media_id || "",
          reward_percentage: 0,
          reward_type: "cashback",
          type: "shoplist",
        },
      };
    };

    const next = () => {
      if (formIsValid.value) {
        if (offerCreationStep.current < offerCreationStep.total) {
          offerCreationStep.current += 1;
          return;
        } else {
          saveShoplist();
        }
      }
    };

    const continueToNext = () => {
      if (isEdit.value) {
        saveShoplist();
      } else {
        next();
      }
    };

    const setPageState = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery?.uuid?.toString()) {
        isEdit.value = true;
        componentBase.value = AppStoryView;
        setDefault();
      } else {
        isEdit.value = false;

        if (newShoplist.instagram_data) {
          setStoryForInstagram();
          componentBase.value = AppStoryView;
        } else {
          componentBase.value = MediaEditor;
        }
      }
    };

    const setStep = () => {
      if (offerCreationStep.current == 1) {
        pageTitle.value = "Offer Media";
        selectedSection.value = "offer_media";
      }
      if (offerCreationStep.current == 2) {
        pageTitle.value = "Offer Details";
        selectedSection.value = "offer_details";
      }
    };

    const removeProduct = (index: number) => {
      newShoplist.products = newShoplist.products.filter(
        (item, baseIndex) => index != baseIndex
      );
    };

    watch(newShoplist, () => {
      Logic.Common.debounce(() => {
        if (newShoplist.instagram_data) {
          setStoryForInstagram();
        }
      }, 500);
    });

    watch(SingleShoplistCollection, () => {
      setStory();
    });

    onIonViewWillEnter(() => {
      setStep();
      setPageState();
      setStory();
      setShippingCategoryOptions();
      setPackageDimensions();
    });

    onIonViewDidEnter(() => {
      setTimeout(() => {
        mediaEditorRef.value?.runOnIonViewDidEnter();
      }, 400);
    });

    onIonViewDidLeave(() => {
      mediaEditorRef.value?.runOnIonViewDidLeave();
      setAppTheme(localStorage.theme, false);
      resetForm();
    });

    watch(offerCreationStep, () => {
      setStep();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("SingleShoplistCollection", SingleShoplistCollection);
      Logic.Shoplist.watchProperty("ManyShippingCategory", ManyShippingCategory);
      Logic.Shoplist.watchProperty("ManyPackageDimensions", ManyPackageDimensions);
      setPageState();
      setShippingCategoryOptions();
      setPackageDimensions();

      if (Logic.Common.currentBuildType() == "web") {
        setStep();
        setStory();
        setTimeout(() => {
          mediaEditorRef.value?.runOnIonViewDidEnter();
        }, 400);
      }
    });

    onUnmounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        mediaEditorRef.value?.runOnIonViewDidLeave();
        resetForm();
        setAppTheme(localStorage.theme, false);
      }
    });

    return {
      Logic,
      newShoplist,
      shoplistPurchaseType,
      FormValidations,
      mediaEditorRef,
      formIsValid,
      isEdit,
      componentBase,
      singleStory,
      showSettings,
      categoryOptions,
      packageDimensionOptions,
      offerCreationStep,
      sections,
      selectedSection,
      formComp,
      pageTitle,
      canSetReward,
      removeProduct,
      continueToNext,
      showAddProduct,
      goBackHandler,
      saveShoplist,
      handleFileURLReady,
      handleFileSelected,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
});
</script>
<style scoped>
.slide-out-bottom-enter-active,
.slide-out-bottom-leave-active {
  transition: transform 0.5s ease;
}
.slide-out-bottom-enter {
  transform: translateY(100%);
}
.slide-out-bottom-leave-to {
  transform: translateY(100%);
}
body {
  background: black !important;
}
</style>
