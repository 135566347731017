<template>
  <app-wrapper>
    <subpage-layout
      :title="isEdit ? ' Update Product' : pageTitle"
      :goBackHandler="goBackHandler"
      :mainSectionClass="'md:!h-fit'"
    >
      <template v-slot:extra-topbar v-if="!isEdit">
        <app-normal-text
          :custom-class="'!text-primary-400 dark:!text-primary-300'"
          >{{
            "Step " +
            `(${productCreationStep.current + "/" + productCreationStep.total})`
          }}
        </app-normal-text>
      </template>
      <template v-slot:extra-topbar-row>
        <div class="w-full flex flex-col pl-4 pb-2" v-if="isEdit">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 pr-4">
              <div
                v-for="(item, index) in sections"
                :key="index"
                :class="`px-4 py-1 ${
                  selectedSection == item.id ? 'bg-primary-main' : 'bg-grey-50'
                }  rounded-[20px]  flex flex-row items-center justify-center cursor-pointer`"
                @click="selectedSection = item.id"
              >
                <app-normal-text
                  :class="`${
                    selectedSection == item.id
                      ? '!text-white'
                      : '!text-grey-700'
                  }`"
                  :custom-class="'whitespace-nowrap'"
                >
                  {{ item.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="`${
            productCreationStep.current == productCreationStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`"
          v-else
        >
          <div
            v-for="(item, index) in productCreationStep.total"
            :key="item"
            :class="`${
              productCreationStep.current >= item
                ? 'bg-highlight-400'
                : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == productCreationStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`"
          ></div>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-4 px-4 pt-3">
        <app-form-wrapper
          ref="formComp"
          :parentRefs="parentRefs"
          class="w-full flex flex-col space-y-4"
        >
          <!-- Product details -->
          <template v-if="selectedSection == 'product_details'">
            <div class="w-full flex flex-col space-y-2">
              <div class="w-full flex flex-col space-y-2">
                <app-normal-text class="!text-gray-700 dark:!text-gray-200">
                  Product Images <span class="text-error-main pl-[2px]">*</span>
                </app-normal-text>
              </div>
              <app-file-attachment
                class="w-full h-[100px] rounded-[10px] border-[1px] border-grey-200 dark:!border-grey-700 border-dashed"
                @update:model-value="onFileAdded"
                @update:local-file-url="onFileUrlGenerated"
                is-wrapper
                is-multiple
                :accept="'image/jpeg,image/gif,image/png'"
              >
                <template v-slot:content>
                  <div
                    class="w-full flex flex-row space-x-2 justify-center items-center"
                  >
                    <app-icon :name="'image'" :customClass="'!h-[18px]'" />
                    <app-normal-text class="!text-gray-700 dark:!text-gray-200">
                      Add images
                    </app-normal-text>
                  </div>
                </template>
              </app-file-attachment>

              <!-- Images -->
              <div
                class="w-full flex flex-col"
                v-if="formData.details.medias.length > 0"
              >
                <div
                  class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
                >
                  <div class="flex flex-row py-2 pr-4">
                    <div
                      class="flex flex-row cursor-pointer pr-2 relative"
                      v-for="(media, index) in formData.details.medias"
                      :key="index"
                    >
                      <span
                        class="absolute top-[-5px] right-0 h-[20px] w-[20px] flex justify-center items-center bg-grey-200 dark:!bg-grey-white rounded-full cursor-pointer"
                        @click="removeMedia(index)"
                      >
                        <app-icon :name="'close'" :customClass="'!h-[10px]'" />
                      </span>
                      <app-image-loader
                        :photo-url="media"
                        :customClass="'w-[100px] h-[100px] rounded-[10px] !border-[1px] !border-gray-200 dark:!border-gray-700'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AppTextField
              :focus-border="'border-primary-400'"
              :has-title="true"
              :custom-class="'!bg-grey-50'"
              :placeholder="'Product Name'"
              name="Product name"
              :rules="[FormValidations.RequiredRule]"
              required
              ref="product_name"
              v-model="formData.details.name"
              :update-value="formData.details.name"
            >
              <template v-slot:title> Product Name </template>
            </AppTextField>

            <AppSelect
              v-if="!hideFields"
              :has-title="true"
              :placeholder-color="'placeholder:text-black'"
              :default-size="'w-full'"
              :placeholder="'Select'"
              :customClass="'dark:!bg-grey-800'"
              :paddings="'px-3 py-3'"
              :ref="'category'"
              auto-complete
              :options="categoryOptions"
              v-model="selectedCategory"
              :theme="Logic.Common.currentAppTheme()"
              required
            >
              <template v-slot:title>Category</template>
            </AppSelect>

            <AppSelect
              v-if="subCategoryOptions.length > 0"
              :has-title="true"
              :placeholder-color="'placeholder:text-black'"
              :default-size="'w-full'"
              :placeholder="'Select'"
              :customClass="'dark:!bg-grey-800'"
              :paddings="'px-3 py-3'"
              :ref="'category'"
              auto-complete
              :options="subCategoryOptions"
              v-model="selectedSubCategory"
              :theme="Logic.Common.currentAppTheme()"
              required
            >
              <template v-slot:title>Sub Category</template>
            </AppSelect>

            <AppSelect
              v-if="brandOptions.length > 0"
              :has-title="true"
              :placeholder-color="'placeholder:text-black'"
              :default-size="'w-full'"
              :placeholder="'Select'"
              :customClass="'dark:!bg-grey-800'"
              :paddings="'px-3 py-3'"
              :ref="'brand'"
              auto-complete
              :options="brandOptions"
              v-model="formData.details.brand_id"
              :theme="Logic.Common.currentAppTheme()"
            >
              <template v-slot:title>Brand</template>
            </AppSelect>

            <AppTextField
              v-if="!hideFields"
              :focus-border="'border-primary-400'"
              :has-title="true"
              :custom-class="'!bg-grey-50'"
              :placeholder="'Enter price'"
              required
              :rules="[FormValidations.RequiredRule]"
              name="price"
              ref="price"
              type="tel"
              is-formatted
              v-model="formData.details.price_per_unit"
            >
              <template v-slot:title>Price Per Unit </template>
              <template v-slot:inner-prefix>
                <span class="pr-1">
                  <app-normal-text :color="'text-grey-700'"
                    >NGN</app-normal-text
                  >
                </span>
              </template>
            </AppTextField>

            <AppTextField
              :focus-border="'border-primary-400'"
              :has-title="true"
              :custom-class="'!bg-grey-50 !items-start'"
              :placeholder="'Enter Description'"
              :max-character="250"
              :textAreaRow="'3'"
              name="Description"
              ref="description"
              :rules="[FormValidations.RequiredRule]"
              is-textarea
              v-model="formData.details.description"
              :update-value="formData.details.description"
            >
              <template v-slot:title>Describe Product </template>
            </AppTextField>

            <app-checkbox v-model="productIsAvailable">
              <app-normal-text> Published </app-normal-text>
            </app-checkbox>
          </template>

          <template v-if="selectedSection == 'product_variant'">
            <div class="w-full flex flex-col space-y-3">
              <div class="w-full flex flex-row justify-between items-center">
                <app-normal-text class="!font-semibold !text-left !text-sm">
                  Custom Attributes
                </app-normal-text>

                <app-button
                  class="!px-3 !py-1 !text-primary-500 !bg-primary-50 dark:!bg-primary-400 dark:!text-primary-50"
                  @click.prevent="addAttribute"
                >
                  + new
                </app-button>
              </div>

              <!-- All attributes -->
              <template v-if="formAttributes.length">
                <div
                  class="w-full grid grid-cols-2 gap-2 justify-between pb-3 border-b-[1px] border-grey-200 dark:!border-grey-700"
                  v-for="(attribute, index) in formAttributes"
                  :key="index"
                >
                  <div class="col-span-1 flex flex-row">
                    <div class="w-full flex flex-col space-y-2">
                      <app-content-editable
                        :placeholder="'Name e.g Size'"
                        :class="'!bg-grey-50 dark:!bg-grey-800 px-2 py-1 !text-black dark:!text-white w-full focus:!outline-primary-500 rounded-[4px]'"
                        v-model="attribute.name"
                        :default-value="attribute.name"
                      />

                      <div
                        class="w-full flex flex-row items-start justify-start"
                      >
                        <app-badge
                          :color="'red'"
                          class="!text-[9px] !rounded-[10px] !py-[1px] cursor-pointer"
                          @click.prevent="removeAttribute(index)"
                        >
                          Remove
                        </app-badge>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-1 flex flex-col">
                    <div class="w-full flex flex-col">
                      <app-text-field
                        :hasTitle="false"
                        :placeholder="'Value. e.g Red'"
                        customClass="!rounded-[4px]"
                        padding="px-2 py-1"
                        v-model="attribute.value"
                        :update-value="attribute.value"
                      >
                        <template #inner-suffix>
                          <span
                            class="text-primary-500 dark:!text-primary-300 cursor-pointer"
                            @click.prevent="addAttributeValue(attribute)"
                          >
                            Add
                          </span>
                        </template>
                      </app-text-field>
                    </div>
                    <!-- Values -->
                    <div class="w-full flex flex-row flex-wrap">
                      <div
                        class="pr-2 pt-2 flex flex-col"
                        v-for="(value, index) in attribute.existing_values"
                        :key="index"
                      >
                        <div
                          class="px-2 py-[2px] bg-white border-[1px] border-grey-200 dark:!bg-grey-800 dark:!border-grey-700 rounded-[4px] flex flex-row items-center justify-center space-x-2"
                        >
                          <app-normal-text>{{ value }}</app-normal-text>
                          <app-icon
                            @click.prevent="
                              removeAttributeValue(attribute, index)
                            "
                            :name="`close-modal${
                              Logic.Common.currentAppTheme() == 'light'
                                ? ''
                                : '-white'
                            }`"
                            :customClass="'!h-[16px] cursor-pointer'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="w-full flex flex-col space-y-2 py-2">
                  <div
                    class="w-full flex flex-col space-y-2 items-center justify-center border-[1px] border-grey-200 dark:!border-grey-700 border-dashed h-[70px] rounded-[10px]"
                  >
                    <app-normal-text class="!text-gray-700 dark:!text-gray-200">
                      No product attributes yet
                    </app-normal-text>
                  </div>
                </div>
              </template>

              <div class="w-full flex flex-row justify-between items-center">
                <app-normal-text class="!font-semibold !text-left !text-sm">
                  Product Variations
                </app-normal-text>

                <app-button
                  :class="`!px-3 !py-1 !text-primary-500 !bg-primary-50 cursor-pointer dark:!bg-primary-400 dark:!text-primary-50 ${
                    formAttributes.length ? '' : '!opacity-50'
                  }`"
                  @click.prevent="
                    formAttributes.length
                      ? addVariant()
                      : showAddAttributeAlert()
                  "
                >
                  + new
                </app-button>
              </div>

              <!-- All Variations -->
              <div class="w-full flex flex-col space-y-2 pt-2">
                <template v-if="formData.variants.length">
                  <div
                    class="w-full flex flex-row space-x-2 justify-between items-start pb-4 border-b-[1px] border-grey-200 dark:!border-grey-700"
                    v-for="(variation, index) in formData.variants"
                    :key="index"
                  >
                    <div class="!w-[70px] flex flex-col space-y-2">
                      <app-file-attachment
                        class="!w-[70px]"
                        v-model:local-file-url="variation.image_url"
                        v-model="variation.image_blob"
                        is-wrapper
                        :accept="'image/jpeg,image/gif,image/png'"
                      >
                        <template v-slot:content>
                          <div
                            class="w-full flex flex-col justify-center items-center"
                          >
                            <app-image-loader
                              :photoUrl="variation.image_url"
                              class="h-[70px] w-[70px] bg-gray-100 rounded-[10px] flex items-center justify-center"
                            >
                              <app-icon
                                :name="'upload-image'"
                                class="!h-[20px]"
                              >
                              </app-icon>
                            </app-image-loader>
                          </div>
                        </template>
                      </app-file-attachment>

                      <div
                        class="w-full flex flex-row items-center justify-center"
                      >
                        <app-badge
                          :color="'red'"
                          class="!text-[9px] !rounded-[10px] !py-[1px] cursor-pointer"
                          @click.prevent="removeVariant(index)"
                        >
                          Remove
                        </app-badge>
                      </div>
                    </div>

                    <div class="w-full flex flex-col space-y-1 flex-grow">
                      <app-content-editable
                        :placeholder="'Variant Name'"
                        :defaultValue="formData.details.name"
                        :class="'!bg-grey-50  dark:!bg-grey-800 px-2 py-1 !text-black dark:!text-white w-full focus:!outline-primary-500 rounded-[4px]'"
                        v-model="variation.name"
                      />

                      <div class="w-full grid grid-cols-2 gap-1">
                        <div class="col-span-1 flex flex-col">
                          <AppTextField
                            :focus-border="'border-primary-400'"
                            :has-title="true"
                            :custom-class="'!bg-grey-50 !rounded-[4px]'"
                            :placeholder="'Enter price'"
                            :padding="'px-2 py-1'"
                            name="price"
                            ref="price"
                            type="tel"
                            is-formatted
                            v-model="variation.price"
                          >
                            <template v-slot:title>Price </template>
                            <template v-slot:inner-prefix>
                              <span class="pr-[1px]">
                                <app-normal-text :color="'text-grey-700'"
                                  >₦</app-normal-text
                                >
                              </span>
                            </template>
                          </AppTextField>
                        </div>

                        <div class="col-span-1 flex flex-col">
                          <AppTextField
                            :type="'tel'"
                            :focus-border="'border-primary-400'"
                            :has-title="true"
                            :custom-class="'!bg-grey-50 !rounded-[4px]'"
                            :placeholder="'Enter quantity'"
                            :padding="'px-2 py-1'"
                            name="Stock quantity"
                            ref="stock_quantity"
                            v-model="variation.stock_quantity"
                            :update-value="variation.stock_quantity"
                          >
                            <template v-slot:title> Quantity </template>
                          </AppTextField>
                        </div>
                      </div>

                      <!-- Attributes -->
                      <div
                        class="w-full flex flex-col pt-2"
                        v-if="formAttributes.length"
                      >
                        <app-normal-text
                          class="!text-gray-700 dark:!text-gray-200"
                        >
                          Attributes
                        </app-normal-text>

                        <div class="w-full flex flex-row flex-wrap">
                          <div
                            class="pr-2 pt-2 flex flex-col"
                            v-for="(attribute, index) in formAttributes"
                            :key="index"
                          >
                            <div
                              v-if="variation.attributes[index]"
                              class="px-2 py-[2px] bg-white border-[1px] border-grey-200 dark:!bg-grey-800 dark:!border-grey-700 rounded-[4px] flex flex-row items-center justify-center space-x-2 relative"
                            >
                              <app-normal-text>{{
                                variation.attributes[index]?.value
                              }}</app-normal-text>

                              <app-select
                                :hasTitle="false"
                                :theme="Logic.Common.currentAppTheme()"
                                :customClass="'!w-[30px]'"
                                defaultSize="!opacity-0 absolute top-0 left-0 h-full w-full"
                                :paddings="'py-1 px-2'"
                                v-model="variation.attributes[index].value"
                                :options="getAttributeOptions(attribute)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div
                    class="w-full flex flex-col space-y-2 items-center justify-center border-[1px] border-grey-200 dark:!border-grey-700 border-dashed h-[70px] rounded-[10px]"
                  >
                    <app-normal-text class="!text-gray-700 dark:!text-gray-200">
                      No product variations yet
                    </app-normal-text>
                  </div>
                </template>
              </div>
            </div>
          </template>

          <!-- Pricing -->
          <template v-if="selectedSection == 'product_pricing'">
            <div class="w-full flex flex-col space-y-2">
              <div
                v-for="(data, index) in formData.pricing"
                :key="index"
                class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full"
              >
                <div class="flex justify-between w-full">
                  <AppNormalText class="!font-semibold">
                    {{ data.area_name }}
                  </AppNormalText>
                </div>

                <div class="h-[1px] bg-grey-50 w-full"></div>

                <div
                  :class="`flex justify-between w-full ${
                    data.id == 'global' ? 'opacity-80' : ''
                  }`"
                >
                  <AppTextField
                    v-if="data.id == 'global'"
                    :focus-border="'border-primary-400'"
                    :has-title="false"
                    :custom-class="'!bg-grey-50'"
                    :placeholder="'Enter price'"
                    required
                    :rules="[FormValidations.RequiredRule]"
                    name="Price per unit"
                    :ref="`amount_${index}`"
                    type="tel"
                    is-formatted
                    v-model="data.amount"
                    :disabled="data.id == 'global'"
                    :update-value="
                      data.id == 'global'
                        ? formData.details.price_per_unit
                        : data.amount
                    "
                  >
                    <template v-slot:inner-prefix>
                      <span class="pr-1">
                        <app-normal-text :color="'text-grey-700'"
                          >NGN</app-normal-text
                        >
                      </span>
                    </template>
                  </AppTextField>
                  <AppTextField
                    v-else
                    :focus-border="'border-primary-400'"
                    :has-title="false"
                    :custom-class="'!bg-grey-50'"
                    :placeholder="'Enter price'"
                    required
                    :rules="[FormValidations.RequiredRule]"
                    name="Price per unit"
                    :ref="`amount_${index}`"
                    type="tel"
                    is-formatted
                    v-model="data.amount"
                  >
                    <template v-slot:inner-prefix>
                      <span class="pr-1">
                        <app-normal-text :color="'text-grey-700'"
                          >NGN</app-normal-text
                        >
                      </span>
                    </template>
                  </AppTextField>
                </div>
              </div>

              <div class="w-full items-center flex justify-center py-2">
                <app-button
                  class="!py-1 !px-3 w-auto !rounded-[5px] cursor-pointer"
                  @click.prevent="showAddArea(null, -1)"
                >
                  Add new store location
                </app-button>
              </div>
            </div>
          </template>

          <template v-if="selectedSection == 'product_reward'">
            <div class="w-full flex flex-col space-y-2">
              <div
                v-if="
                  formData.reward_settings.filter(
                    (item) => item.quantity == '-1'
                  ).length == 0
                "
                class="w-full flex flex-row px-3 py-3 bg-secondary-50 rounded-[10px]"
              >
                <app-normal-text class="text-secondary-main">
                  Please add a reward setting for any quantity purchased
                </app-normal-text>
              </div>
              <div
                class="w-full px-5 py-5 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
                @click="showAddReward(null, -1)"
              >
                <app-normal-text class="text-center text-gray-700">
                  + Add reward settings
                </app-normal-text>
              </div>
            </div>

            <div class="w-full flex flex-col space-y-2">
              <div
                v-for="(data, index) in formData.reward_settings"
                :key="index"
                class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer"
                @click="showAddReward(data, index)"
              >
                <div class="flex justify-between w-full">
                  <AppNormalText class="!font-semibold"
                    >{{
                      parseFloat(data.quantity) > 0 ? data.quantity : "Any"
                    }}
                    Quantit{{ parseFloat(data.quantity) > 1 ? "ies" : "y" }}
                  </AppNormalText>

                  <app-badge
                    :color="data.type == 'cashback' ? 'purple' : 'green'"
                    :class="'capitalize'"
                  >
                    {{ data.type }}
                  </app-badge>
                </div>

                <div class="h-[1px] bg-grey-50 w-full"></div>

                <div class="flex justify-between w-full">
                  <AppNormalText
                    :color="'text-grey-700'"
                    class="!text-[12px]"
                    :custom-class="'gap-2'"
                  >
                    Reward Percentage
                  </AppNormalText>
                  <AppNormalText> {{ data.percentage }}% </AppNormalText>
                </div>
              </div>
            </div>
          </template>

          <template v-if="selectedSection == 'product_commission'">
            <AppTextField
              :focus-border="'border-primary-400'"
              :has-title="true"
              :custom-class="'!bg-grey-50'"
              :placeholder="'Enter percentage'"
              name="Affiliate Commission"
              :rules="[FormValidations.RequiredRule]"
              :required="formData.product_commission.active"
              type="number"
              v-model="formData.product_commission.percentage"
              :update-value="formData.product_commission.percentage"
            >
              <template v-slot:title>Affiliate Commission </template>
              <template v-slot:inner-suffix>
                <span class="pl-1">
                  <app-normal-text :color="'text-grey-700'">%</app-normal-text>
                </span>
              </template>
            </AppTextField>

            <app-checkbox v-model="formData.product_commission.active">
              <app-normal-text> Allow Affiliate Sales </app-normal-text>
            </app-checkbox>
          </template>

          <template v-if="selectedSection == 'delivery'">
            <app-checkbox v-model="formData.delivery.has_delivery">
              <app-normal-text> Enable Delivery </app-normal-text>
            </app-checkbox>

            <template v-if="formData.delivery.has_delivery">
              <AppSelect
                v-if="!hideFields"
                :has-title="true"
                :placeholder-color="'placeholder:text-black'"
                :default-size="'w-full'"
                :placeholder="'Select'"
                :customClass="'dark:!bg-grey-800'"
                :paddings="'px-3 py-3'"
                :ref="'weight'"
                auto-complete
                :options="weightOptions"
                @on-option-selected="onWeightChange"
                :theme="Logic.Common.currentAppTheme()"
                v-model="formData.details.weight"
                required
              >
                <template v-slot:title>Product Weight</template>
              </AppSelect>

              <template v-if="showCustomWeight">
                <AppTextField
                  :focus-border="'border-primary-400'"
                  :has-title="true"
                  :custom-class="'!bg-grey-50'"
                  :placeholder="'Enter weight'"
                  name="Custom weight"
                  :rules="[FormValidations.RequiredRule]"
                  required
                  type="tel"
                  ref="custom_weight"
                  v-model="customWeight"
                  :update-value="customWeight"
                >
                  <template v-slot:title> Custom Weight (KG) </template>
                </AppTextField>
              </template>

              <app-select
                :options="shippingCategoryOptions"
                :has-title="true"
                :placeholder="'Select Category'"
                :paddings="'px-4 py-3'"
                auto-complete
                v-model="formData.delivery.shipping_category_id"
                :theme="Logic.Common.currentAppTheme()"
                required
              >
                <template v-slot:title> Shipping Category </template>
              </app-select>

              <AppSelect
                :has-title="true"
                :placeholder-color="'placeholder:text-black'"
                :default-size="'w-full'"
                :placeholder="'Select'"
                :paddings="'px-4 py-3'"
                :ref="'product_size'"
                :options="packageDimensionOptions"
                :theme="Logic.Common.currentAppTheme()"
                v-model="formData.details.package_dimension_id"
                required
              >
                <template v-slot:title> Package Dimension </template>
              </AppSelect>
            </template>

            <div
              class="w-full flex flex-col space-y-2"
              v-if="formData.delivery.has_delivery"
            >
              <div
                v-if="ManyDeliveryLocations?.data.length == 0"
                class="w-full px-3 py-3 bg-primary-50 rounded-[10px] flex flex-col space-y-2 justify-center items-center"
              >
                <app-normal-text
                  class="text-center text-gray-700 dark:!text-gray-200"
                >
                  You have no pick up areas yet
                </app-normal-text>
              </div>

              <template v-if="deliveryAreas.length > 0">
                <div class="w-full flex flex-col space-y-1">
                  <app-normal-text class="!text-gray-700 dark:!text-gray-200">
                    Select the pickup areas for this product
                  </app-normal-text>

                  <div class="w-full items-center flex justify-center py-2">
                    <app-button
                      class="!py-1 !px-3 w-auto !rounded-[5px] cursor-pointer"
                      @click.prevent="showAddArea(null, -1)"
                    >
                      Create new pickup areas
                    </app-button>
                  </div>

                  <div class="w-full flex flex-col">
                    <app-checkbox
                      v-model="selectedAllAreas"
                      class="!text-gray-500 dark:!text-gray-400 !text-[12px]"
                    >
                      Select all areas
                    </app-checkbox>
                  </div>
                </div>

                <app-business-pick-area
                  v-for="(data, index) in deliveryAreas"
                  :class="` border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full cursor-pointer ${
                    formData.delivery.areas.includes(data.id)
                      ? 'border-primary-400 border-[2px]'
                      : 'border-grey-50'
                  }`"
                  :key="index"
                  :data="data"
                  @click.stop="toggleFromDeliveryArea(data)"
                >
                </app-business-pick-area>
              </template>
            </div>
          </template>
        </app-form-wrapper>

        <!-- Spacer -->
        <div class="h-[70px]"></div>
      </div>

      <fixed-container custom-class="pt-4">
        <AppButton
          @click="continueToNext()"
          :custom-class="'h-fit w-full '"
          :padding="'py-3 px-3'"
          :class="`${!formIsValid ? 'opacity-50' : ''}`"
          :loading="updateLoading"
        >
          {{ isEdit ? "Update" : "Continue" }}
        </AppButton>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppTextField,
  AppButton,
  AppCheckbox,
  AppSelect,
  AppFileAttachment,
  AppImageLoader,
  AppIcon,
  AppBadge,
  AppFormWrapper,
  AppContentEditable,
  AppBusinessPickArea,
} from "@shpt/ui-components";
import { FormValidations } from "@shpt/ui-components/src/composable";
import { SelectOption } from "@shpt/ui-components/src/types";
import {
  DeliveryLocation,
  RewardType,
  VariantSelectedOptions,
} from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import {
  setBusinessAdminOptions,
  showAddArea,
} from "@shpt/ui-components/src/composable/shop";

export default defineComponent({
  components: {
    AppButton,
    AppTextField,
    AppNormalText,
    AppCheckbox,
    AppSelect,
    AppFileAttachment,
    AppImageLoader,
    AppIcon,
    AppBadge,
    AppFormWrapper,
    AppBusinessPickArea,
    AppWrapper,
    AppContentEditable,
    FixedContainer,
  },
  name: "AddNewProductPage",
  layout: "SubPageLayout",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategories",
        method: "GetShoplistProductCategories",
        params: [1, 500, "CREATED_AT", "DESC", ""],
        requireAuth: true,
        ignoreProperty: true,
      },
      {
        domain: "Shoplist",
        property: "ManyShoplistBrands",
        method: "GetShoplistBrands",
        params: [1, 500],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "SingleShoplistProduct",
        method: "GetShoplistProduct",
        params: [],
        useRouteQuery: true,
        queries: ["uuid"],
        requireAuth: true,
        condition: {
          routeSearchItem: "query",
          searchQuery: "uuid",
        },
      },
      {
        domain: "User",
        property: "ManyBusinessAdmins",
        method: "GetBusinessAdmins",
        params: [
          1,
          50,
          "CREATED_AT",
          "DESC",
          `
         {
        column: UUID
        operator: EQ
        value: "${Logic.Auth.GetCurrentBusiness()?.uuid}"
         }
        `,
          `{
        column: INVITATION_ACCEPTED_AT
        operator: NEQ
        value: null
        }`,
        ],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyPackageDimensions",
        method: "GetPackageDimension",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyShippingCategory",
        method: "GetShippingCategory",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Add New Product Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Add Product",
    });

    const selectedSection = ref("product_details");
    const selectedSubCategory = ref("");
    const selectedCategory = ref("1");
    const productIsAvailable = ref(false);

    const ManyCategories = ref(Logic.Shoplist.ManyCategories);
    const ManyDeliveryLocations = ref(Logic.Shoplist.ManyDeliveryLocations);
    const SingleShoplistProduct = ref(Logic.Shoplist.SingleShoplistProduct);
    const ManyPackageDimensions = ref(Logic.Shoplist.ManyPackageDimensions);
    const ManyShippingCategory = ref(Logic.Shoplist.ManyShippingCategory);
    const ManyShoplistBrands = ref(Logic.Shoplist.ManyShoplistBrands);

    const pageTitle = ref("Product Details");

    const isEdit = ref(false);

    const updateLoading = ref(false);

    const categoryOptions = reactive<SelectOption[]>([]);
    const subCategoryOptions = reactive<SelectOption[]>([]);
    const shippingCategoryOptions = reactive<SelectOption[]>([]);
    const brandOptions = reactive<SelectOption[]>([]);

    const selectedAllAreas = ref(false);

    const customWeight = ref("");

    const hideFields = ref(false);

    const showCustomWeight = ref(false);

    const formComp = ref();

    const productCreationStep = reactive({
      current: 1,
      total: 4,
    });

    const sections = reactive([
      {
        id: "product_details",
        title: "Product Details",
      },
      {
        id: "product_variant",
        title: "Product Variants",
      },
      {
        id: "product_pricing",
        title: "Pricing",
      },
      // {
      //   id: "product_commission",
      //   title: "Affiliate Settings",
      // },
      {
        id: "delivery",
        title: "Delivery",
      },
    ]);

    const packageDimensionOptions = reactive<SelectOption[]>([]);

    const formAttributes = reactive<
      {
        id: string;
        name: string;
        value: string;
        existing_values: string[];
      }[]
    >([]);

    const formData = reactive<{
      details: {
        name: string;
        category_id: string;
        sub_category_id: string;
        price_per_unit: string;
        description: string;
        available: boolean;
        image_url: string;
        image_blob: string;
        stock_quantity: string;
        weight: string;
        brand_id: string;
        package_dimension_id: string;
        medias: string[];
        medias_blobs: {
          uuid: string;
          attachment: string;
        }[];
      };
      attributes: {
        id: string;
        name: string;
        value: string;
        existing_values: string[];
      }[];
      variants: {
        id: string;
        name: string;
        price: string;
        stock_quantity: string;
        image_url: string;
        image_blob: string;
        is_available: boolean;
        attributes: {
          name: string;
          value: string;
        }[];
      }[];
      reward_settings: {
        id: string;
        quantity: string;
        type: "cashback" | "discount";
        percentage: string;
      }[];
      product_commission: {
        active: boolean;
        percentage: string;
      };
      delivery: {
        has_delivery: boolean;
        areas: string[];
        shipping_category_id: string;
      };
      pricing: {
        id: string;
        area_name: string;
        area_id: string;
        amount: string;
      }[];
    }>({
      details: {
        name: "",
        category_id: "",
        sub_category_id: "",
        price_per_unit: "",
        description: "",
        available: false,
        image_url: "",
        image_blob: "",
        stock_quantity: "1",
        weight: "",
        package_dimension_id: "",
        medias: [],
        medias_blobs: [],
        brand_id: "",
      },
      attributes: [],
      variants: [],
      reward_settings: [
        {
          id: "",
          quantity: "-1",
          type: "cashback",
          percentage: "1",
        },
      ],
      product_commission: {
        active: true,
        percentage: "1",
      },
      delivery: {
        has_delivery: true,
        areas: [],
        shipping_category_id: "",
      },
      pricing: [
        {
          id: "global",
          area_name: "Global",
          area_id: "0",
          amount: "0",
        },
      ],
    });

    const weightOptions = reactive<SelectOption[]>([
      {
        key: "0.05",
        value: "0.05 kg (Very Light)",
      },
      {
        key: "0.5",
        value: "0.5 kg (Light)",
      },
      {
        key: "1",
        value: "1 kg (Medium Light)",
      },
      {
        key: "2",
        value: "2 kg (Medium)",
      },
      {
        key: "5",
        value: "5 kg (Medium Heavy)",
      },
      {
        key: "10",
        value: "10 kg (Heavy)",
      },
      {
        key: "20",
        value: "20 kg (Extra Heavy)",
      },
      {
        key: "custom",
        value: "Custom (Enter manually)",
      },
    ]);

    const showAddAttributeAlert = () => {
      Logic.Common.showAlert({
        show: true,
        message: "Please add at least one attribute before adding a variant",
        type: "error",
      });
    };

    const resetForm = () => {
      selectedSection.value = "product_details";
      selectedAllAreas.value = false;
      selectedCategory.value = "1";
      selectedSubCategory.value = "";
      formData.details = {
        name: "",
        category_id: "",
        sub_category_id: "",
        price_per_unit: "",
        description: "",
        available: false,
        image_url: "",
        image_blob: "",
        stock_quantity: "1",
        weight: "",
        package_dimension_id: "",
        medias: [],
        medias_blobs: [],
        brand_id: "",
      };
      formData.reward_settings = [
        {
          id: "",
          quantity: "-1",
          type: "cashback",
          percentage: "1",
        },
      ];
      formData.product_commission = {
        active: true,
        percentage: "1",
      };
      formData.delivery = {
        has_delivery: true,
        areas: [],
        shipping_category_id: "",
      };
    };

    const deliveryAreas = reactive<
      {
        location: string;
        cost: number;
        active: boolean;
        id: string;
        area: string[];
        data: DeliveryLocation;
      }[]
    >([]);

    const setPackageDimensions = () => {
      packageDimensionOptions.length = 0;

      ManyPackageDimensions.value?.forEach((item) => {
        packageDimensionOptions.push({
          key: item.name,
          value: item.name,
          imageUrl: item.description_image_url,
          extras: JSON.stringify(item),
        });
      });
    };

    const formIsValid = computed(() => {
      const hasDetails =
        formData.details.name != "" &&
        selectedCategory.value != "" &&
        selectedSubCategory.value != "" &&
        formData.details.medias.length > 0 &&
        formData.details.price_per_unit != "" &&
        parseFloat(formData.details.price_per_unit?.replace(/,/g, "")) > 0;

      const hasVariants =
        formData.variants.length > 0 &&
        formData.variants.filter((item) => item.is_available).length > 0;

      const hasRewardSettings =
        formData.reward_settings.filter((item) => item.quantity == "-1")
          .length > 0;

      const hasCommission =
        (formData.product_commission.active &&
          formData.product_commission.percentage != "") ||
        formData.product_commission.active == false;

      const hasLocation =
        (formData.delivery.has_delivery &&
          formData.delivery.areas.length > 0 &&
          formData.details.package_dimension_id != "" &&
          formData.details.weight != "" &&
          formData.delivery.shipping_category_id != "") ||
        formData.delivery.has_delivery == false;

      const allPricingIsGreaterThanZero =
        formData.pricing.filter(
          (item) => parseFloat(item.amount?.replace(/,/g, "")) > 0
        ).length == formData.pricing.length;

      if (isEdit.value) {
        return hasDetails && hasRewardSettings && hasCommission && hasLocation;
      } else {
        if (productCreationStep.current == 1) {
          return hasDetails;
        } else if (productCreationStep.current == 2) {
          return hasVariants;
        } else if (productCreationStep.current == 3) {
          return allPricingIsGreaterThanZero;
          // } else if (productCreationStep.current == 4) {
          //   return hasCommission;
        } else {
          return hasLocation;
        }
      }
    });

    const images = ref<Array<any>>([]);
    const selectedInventory = ref<any>("66");

    function getUrl(File: any) {
      images.value.push({
        name: File.name,
        url: URL.createObjectURL(File),
      });
    }

    function removeImage(imageIndex: any) {
      images.value.splice(imageIndex, 1);
    }

    const saveProduct = () => {
      formComp.value.validate();

      if (updateLoading.value) {
        return;
      }

      if (formIsValid.value) {
        updateLoading.value = true;
        if (isEdit.value) {
          const productAttributes: VariantSelectedOptions[] = [];

          formAttributes.forEach((item) => {
            item.existing_values.forEach((value) => {
              productAttributes.push({
                name: item.name,
                value: value,
              });
            });
          });

          Logic.Shoplist.UpdateShoplistProductForm = {
            status: productIsAvailable.value ? "active" : "draft",
            name: formData.details.name,
            product_id: parseInt(SingleShoplistProduct.value?.id || ""),
            medias: formData.details.medias_blobs.map((item) => {
              return {
                uuid: item.uuid ? item.uuid : Logic.Common.makeid(10),
                attachment: item?.attachment || undefined,
              };
            }),
            variants: formData.variants.map((item) => {
              return {
                id: item.id,
                is_available: item.is_available,
                name: item.name,
                price: item.price,
                inventory_quantity: parseInt(item.stock_quantity),
                selected_options: item.attributes,
                image_url: item.image_blob ? item.image_blob : undefined,
              };
            }),
            primary_image: formData.details.medias_blobs[0]?.attachment
              ? formData.details.medias_blobs[0]?.attachment
              : undefined,
            stock_quantity: parseInt(formData.details.stock_quantity),
            category_id: parseInt(selectedCategory.value),
            subcategory_id: parseInt(selectedSubCategory.value),
            regular_price: formData.details.price_per_unit?.replace(/,/g, ""),
            affiliate_commission: formData.product_commission.percentage,
            affiliateable: formData.product_commission.active,
            deliverable: formData.delivery.has_delivery,
            delivery_areas: formData.delivery.areas,
            description: formData.details.description,
            weight:
              formData.details.weight == "custom"
                ? customWeight.value
                : formData.details.weight,
            dimension_size:
              packageDimensionOptions.filter(
                (item) => item.key == formData.details.package_dimension_id
              )[0]?.extras || "",
            shipping_category:
              shippingCategoryOptions.filter(
                (item) => item.key == formData.delivery.shipping_category_id
              )[0]?.extras || "",
            attributes: productAttributes,
            brand_id: formData.details.brand_id
              ? parseInt(formData.details.brand_id)
              : undefined,
            pricing: formData.pricing
              .filter((item) => item.id != "global")
              .map((item) => {
                return {
                  pricing_id: parseInt(item.id),
                  amount: item.amount?.replace(/,/g, ""),
                  supported_area_id: parseInt(item.area_id),
                };
              }),
          };

          Logic.Shoplist.UpdateShoplistProduct()
            ?.then(async (data) => {
              if (data) {
                Logic.Common.showAlert({
                  show: true,
                  message: "Product updated successfully",
                  type: "success",
                });
                Logic.Common.hideLoader();
                Logic.Shoplist.GetShoplistProduct(
                  SingleShoplistProduct.value?.uuid || ""
                );
                updateLoading.value = false;
              } else {
                updateLoading.value = false;
                Logic.Common.hideLoader();
              }
            })
            .catch(() => {
              updateLoading.value = false;
              Logic.Common.hideLoader();
            });
        } else {
          Logic.Shoplist.CreateShoplistProductForm = {
            status: productIsAvailable.value ? "active" : "draft",
            name: formData.details.name,
            category_id: parseInt(selectedCategory.value),
            subcategory_id: parseInt(selectedSubCategory.value),
            primary_image: formData.details.medias_blobs[0]?.attachment
              ? formData.details.medias_blobs[0]?.attachment
              : undefined,
            stock_quantity: parseInt(formData.details.stock_quantity),
            regular_price: formData.details.price_per_unit?.replace(/,/g, ""),
            medias: formData.details.medias_blobs.map((item) => {
              return {
                uuid: item.uuid ? item.uuid : Logic.Common.makeid(10),
                attachment: item.attachment,
              };
            }),
            variants: formData.variants.map((item) => {
              return {
                is_available: item.is_available,
                name: item.name,
                price: item.price,
                inventory_quantity: parseInt(item.stock_quantity),
                selected_options: item.attributes,
                image_url: item.image_blob ? item.image_blob : undefined,
              };
            }),
            reward_settings: formData.reward_settings.map((item: any) => {
              return {
                percentage: item.percentage.toString(),
                quantity: item.quantity,
                type:
                  item.type == "cashback"
                    ? RewardType.Cashback
                    : RewardType.Discount,
              };
            }),
            affiliate_commission: formData.product_commission.percentage,
            affiliateable: formData.product_commission.active,
            deliverable: formData.delivery.has_delivery,
            delivery_areas: formData.delivery.areas,
            description: formData.details.description,
            weight:
              formData.details.weight == "custom"
                ? customWeight.value
                : formData.details.weight,
            dimension_size:
              packageDimensionOptions.filter(
                (item) => item.key == formData.details.package_dimension_id
              )[0]?.extras || "",
            shipping_category:
              shippingCategoryOptions.filter(
                (item) => item.key == formData.delivery.shipping_category_id
              )[0]?.extras || "",
            brand_id: formData.details.brand_id
              ? parseInt(formData.details.brand_id)
              : undefined,
            pricing: formData.pricing
              .filter((item) => item.id != "global")
              .map((item) => {
                return {
                  amount: item.amount?.replace(/,/g, ""),
                  supported_area_id: parseInt(item.area_id),
                };
              }),
          };

          Logic.Shoplist.CreateShoplistProduct()
            ?.then(async (data) => {
              if (data) {
                Logic.Common.showLoader({ show: true, loading: true });
                Logic.Shoplist.GetShoplistProducts(
                  1,
                  15,
                  "CREATED_AT",
                  "DESC",
                  `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                    }`
                ).then(() => {
                  resetForm();
                  updateLoading.value = false;
                  Logic.Common.hideLoader();
                  Logic.Common.goBack();
                });
              } else {
                updateLoading.value = false;
                Logic.Common.hideLoader();
              }
            })
            .catch(() => {
              updateLoading.value = false;
              Logic.Common.hideLoader();
            });
        }
      }
    };

    const continueToNext = () => {
      if (isEdit.value) {
        saveProduct();
      } else {
        next();
      }
    };

    const onFileAdded = (data: any) => {
      data.forEach((item: any) => {
        formData.details.medias_blobs.push({
          uuid: Logic.Common.makeid(10),
          attachment: item,
        });
      });
    };

    const onFileUrlGenerated = (data: any) => {
      formData.details.medias.push(data);
    };

    const removeMedia = (index: number) => {
      formData.details.medias_blobs.splice(index, 1);
      formData.details.medias.splice(index, 1);
    };

    const showAddReward = (rewardItem: any, index: number) => {
      Logic.Common.showModal({
        show: true,
        type: "product_reward_setting",
        title: index == -1 ? "Add Reward Settings" : "Update Reward Settings",
        action: async (data: any, isDelete: boolean) => {
          if (isDelete) {
            if (isEdit.value) {
              await Logic.Shoplist.DeleteShoplistProductReward(
                parseFloat(rewardItem.id)
              );
            }
            formData.reward_settings = formData.reward_settings.filter(
              (item, eachIndex) => eachIndex != index
            );
          } else {
            if (index == -1) {
              if (isEdit.value) {
                Logic.Shoplist.CreateShoplistProductRewardForm = {
                  product_id: parseInt(SingleShoplistProduct.value?.id || "0"),
                  percentage: data.percentage.toString(),
                  quantity: data.quantity.toString(),
                  type:
                    data.type == "cashback"
                      ? RewardType.Cashback
                      : RewardType.Discount,
                };
                Logic.Shoplist.CreateShoplistProductReward()?.then(
                  async (data) => {
                    if (data) {
                      await Logic.Shoplist.GetShoplistProduct(
                        SingleShoplistProduct.value?.uuid || ""
                      );
                    }
                  }
                );
              }
              formData.reward_settings.push(data);
            } else {
              if (isEdit.value) {
                Logic.Common.showModal({ show: false });
                Logic.Shoplist.UpdateShoplistProductRewardForm = {
                  reward_id: parseInt(rewardItem.id || "0"),
                  percentage: data.percentage.toString(),
                  quantity: data.quantity.toString(),
                  type:
                    data.type == "cashback"
                      ? RewardType.Cashback
                      : RewardType.Discount,
                };
                Logic.Shoplist.UpdateShoplistProductReward()?.then(
                  async (data) => {
                    if (data) {
                      await Logic.Shoplist.GetShoplistProduct(
                        SingleShoplistProduct.value?.uuid || ""
                      );
                    }
                  }
                );
              }
              formData.reward_settings[index] = data;
            }
          }

          Logic.Common.showModal({ show: false });
        },
        extraData: {
          existing_quantities: formData.reward_settings.map((item) =>
            item.quantity.toString()
          ),
          is_edit: index > -1,
          default: rewardItem,
        },
      });
    };

    const onWeightChange = (option: SelectOption) => {
      if (option.key == "custom") {
        showCustomWeight.value = true;
        formData.details.weight = "";
      } else {
        showCustomWeight.value = false;
      }
    };

    const toggleFromDeliveryArea = (item: any) => {
      if (formData.delivery.areas.includes(item.id)) {
        formData.delivery.areas = formData.delivery.areas.filter(
          (eachItem) => eachItem != item.id
        );
      } else {
        formData.delivery.areas = [item.id];
      }
    };

    const setOptions = () => {
      categoryOptions.length = 0;

      ManyCategories.value?.data.forEach((item) => {
        if (item.parent_category_id == undefined) {
          categoryOptions.push({
            key: item.id,
            value: item.name,
          });
        }
      });
    };

    const setSubCategoryOptions = () => {
      subCategoryOptions.length = 0;

      setTimeout(() => {
        if (!selectedCategory.value) {
          selectedCategory.value = categoryOptions[0].key;
        }

        if (selectedCategory.value) {
          const category = categoryOptions.find(
            (item) => item.key == selectedCategory.value
          );
          if (category) {
            ManyCategories.value?.data.forEach((item) => {
              if (item.parent_category_id == category.key) {
                subCategoryOptions.push({
                  key: item.id,
                  value: item.name,
                });
              }
            });

            const selectedSubCategoryIsInCurrent = subCategoryOptions.find(
              (item) => selectedSubCategory.value == item.key
            );

            if (!selectedSubCategoryIsInCurrent && subCategoryOptions.length) {
              selectedSubCategory.value = subCategoryOptions[0].key;
            }
          }
        }
      }, 400);
    };

    const setShippingCategoryOptions = () => {
      shippingCategoryOptions.length = 0;
      ManyShippingCategory.value?.forEach((item: any) => {
        shippingCategoryOptions.push({
          key: item.category_id,
          value: item.category,
          extras: JSON.stringify(item),
        });
      });
    };

    const setBrandOptions = () => {
      brandOptions.length = 0;
      ManyShoplistBrands.value?.data.forEach((item) => {
        brandOptions.push({
          key: item.id,
          value: item.business_name || "",
        });
      });
    };

    const removeAttribute = (index: number) => {
      formAttributes.splice(index, 1);
      realignVariantAttributes();
    };

    const removeAttributeValue = (attribute: any, index: number) => {
      attribute.existing_values.splice(index, 1);
      realignVariantAttributes();
    };

    const realignVariantAttributes = () => {
      Logic.Common.debounce(() => {
        formData.variants.forEach((variant) => {
          const oldVariant = JSON.parse(JSON.stringify(variant));
          variant.attributes = [];
          setTimeout(() => {
            formAttributes.forEach((attribute, index) => {
              const oldValueIsStillInAttribute = oldVariant.attributes[index]
                ? oldVariant.attributes[index]
                : variant.attributes.find(
                    (item: any) => item.value == attribute.value
                  );

              variant.attributes.push({
                name: attribute.name,
                value: oldValueIsStillInAttribute?.value
                  ? oldValueIsStillInAttribute.value
                  : attribute.existing_values.length > 0
                  ? attribute.existing_values[0]
                  : "",
              });
            });
          }, 400);
        });
      }, 400);
    };

    const addAttribute = () => {
      formAttributes.push({
        id: Logic.Common.makeid(10),
        name: "",
        value: "",
        existing_values: [],
      });
    };

    const addAttributeValue = (attribute: any) => {
      if (attribute.value) {
        attribute.existing_values.push(attribute.value);
        attribute.value = "";
        realignVariantAttributes();
      }
    };

    const addVariant = () => {
      formData.variants.push({
        id: Logic.Common.makeid(10),
        attributes: formAttributes.map((item) => {
          return {
            name: item.name,
            value:
              item.existing_values.length > 0 ? item.existing_values[0] : "",
          };
        }),
        name: formData.details.name,
        price: formData.details.price_per_unit,
        stock_quantity: formData.details.stock_quantity,
        image_url: "",
        image_blob: "",
        is_available: true,
      });
    };

    const getAttributeOptions = (attribute: any): SelectOption[] => {
      return attribute.existing_values.map((item: any) => {
        return {
          key: item,
          value: item,
        };
      });
    };

    const removeVariant = (index: number) => {
      formData.variants.splice(index, 1);
    };

    const getDeliveryLocations = () => {
      Logic.Shoplist.GetDeliveryLocation(
        1,
        300,
        "CREATED_AT",
        "DESC",
        `{
        column: BUSINESS_PROFILE_ID
        operator: EQ
        value: ${Logic.Auth.GetCurrentBusiness()?.id}
      }`
      );
    };

    const goBackHandler = () => {
      if (isEdit.value) {
        Logic.Common.goBack();
      } else {
        if (productCreationStep.current <= 1) {
          Logic.Common.goBack();
        } else {
          productCreationStep.current--;
        }
      }
    };

    const next = () => {
      if (formIsValid.value) {
        if (productCreationStep.current == 1 && formData.variants.length == 0) {
          // create default variant
          addVariant();
        }
        if (productCreationStep.current < productCreationStep.total) {
          productCreationStep.current += 1;
          return;
        } else {
          saveProduct();
        }
      }
    };

    const setStep = () => {
      if (productCreationStep.current == 1) {
        pageTitle.value = "Product Details";
        selectedSection.value = "product_details";
      }
      if (productCreationStep.current == 2) {
        pageTitle.value = "Product Variants";
        selectedSection.value = "product_variant";
      }
      if (productCreationStep.current == 3) {
        pageTitle.value = "Pricing";
        selectedSection.value = "product_pricing";
      }
      // if (productCreationStep.current == 4) {
      //   pageTitle.value = "Affiliate Settings";
      //   selectedSection.value = "product_commission";
      // }
      if (productCreationStep.current == 4) {
        pageTitle.value = "Delivery";
        selectedSection.value = "delivery";
      }
    };

    const setDeliveryLocations = () => {
      deliveryAreas.length = 0;

      ManyDeliveryLocations.value?.data.forEach((item) => {
        deliveryAreas.push({
          location: `${item.supported_area?.area_name}, ${item.supported_area?.state_name}`,
          active: item.status == "active",
          cost: parseFloat(item.fixed_cost || "0"),
          id: item.id,
          area: [item.supported_area?.id || "0"],
          data: item,
        });
      });
    };

    const setPricingData = () => {
      // Set global pricing
      if (isEdit.value) {
        formData.pricing = [
          {
            id: "global",
            area_name: "Global",
            area_id: "0",
            amount: SingleShoplistProduct.value?.regular_price || "0",
          },
        ];
      } else {
        formData.pricing = [
          {
            id: "global",
            area_name: "Global",
            area_id: "0",
            amount: formData.details.price_per_unit,
          },
        ];
      }

      Logic.Shoplist.ManyDeliveryLocations?.data.forEach((item) => {
        const productPricing = SingleShoplistProduct.value?.pricing?.find(
          (singleItem) => singleItem?.supported_areas_id == item.id
        );
        formData.pricing.push({
          id: productPricing?.id || "0",
          amount: productPricing
            ? productPricing.amount
            : SingleShoplistProduct.value?.regular_price || "0",
          area_id: item.id,
          area_name: item.title || "",
        });
      });
    };

    const setDefault = (fullReset = true) => {
      if (SingleShoplistProduct.value) {
        if (fullReset) {
          selectedSection.value = "product_details";
          selectedAllAreas.value =
            ManyDeliveryLocations.value?.data.length ==
            SingleShoplistProduct.value?.delivery_areas.length;
        }

        let weight = "";

        if (SingleShoplistProduct.value?.weight) {
          const weightOption = weightOptions.find(
            (item) => item.key == SingleShoplistProduct.value?.weight
          );
          if (weightOption) {
            weight = weightOption.key;
          } else {
            weight = "custom";
            showCustomWeight.value = true;
            customWeight.value = SingleShoplistProduct.value?.weight;
          }
        }

        const medias = SingleShoplistProduct.value?.medias?.map((item) => {
          return {
            uuid: item.uuid,
            attachment: "",
          };
        });

        let mediaUrls = SingleShoplistProduct.value?.medias?.map(
          (item) => item.media_url || ""
        );

        if (mediaUrls?.length == 0) {
          mediaUrls = [SingleShoplistProduct.value.primary_image_url];
        }

        // Attributes
        const attributes: {
          id: string;
          name: string;
          value: string;
          existing_values: string[];
        }[] = [];

        const firstVariant = SingleShoplistProduct.value?.variants
          ? SingleShoplistProduct.value?.variants[0]
          : undefined;

        firstVariant?.selected_options?.forEach((item) => {
          attributes.push({
            id: Logic.Common.makeid(10),
            name: item.name,
            value: "",
            existing_values: [],
          });
        });

        // Variants
        const variants = SingleShoplistProduct.value?.variants?.map((item) => {
          // Add existing values if it does exist for attribute
          if (
            item?.selected_options?.length &&
            item?.selected_options?.length > 0
          ) {
            item?.selected_options.forEach((item) => {
              const attribute = attributes.find(
                (attr) => attr.name == item.name
              );
              if (attribute) {
                attribute.existing_values.push(item.value);
              }
            });
          }

          let selectedOptions = item?.selected_options?.map((item) => {
            return {
              name: item.name,
              value: item.value,
            };
          });

          if (selectedOptions && selectedOptions.length == 0) {
            selectedOptions = attributes.map((item) => {
              return {
                name: item.name,
                value: item.existing_values[0],
              };
            });
          }

          return {
            id: item?.id || "",
            is_available: item?.is_available || false,
            name: item?.name || "",
            price: item?.price || "",
            stock_quantity: item?.inventory_quantity.toString() || "0",
            image_url: item?.base_image_url || "",
            image_blob: "",
            attributes: selectedOptions || [],
          };
        });

        if (SingleShoplistProduct.value?.attributes?.length) {
          const attributes: {
            id: string;
            name: string;
            value: string;
            existing_values: string[];
          }[] = [];

          SingleShoplistProduct.value?.attributes?.forEach((item) => {
            const attributeExist = attributes.find(
              (attr) => attr.name == item.name
            );
            if (!attributeExist) {
              attributes.push({
                id: Logic.Common.makeid(10),
                name: item.name,
                value: "",
                existing_values: [item.value],
              });
            } else {
              attributeExist.existing_values.push(item.value);
            }
          });

          formAttributes.length = 0;

          formAttributes.push(...attributes);
        } else {
          formAttributes.length = 0;
          formAttributes.push(...attributes);
        }

        formData.variants = variants || [];

        selectedSubCategory.value =
          SingleShoplistProduct.value.sub_category?.id || "";
        selectedCategory.value = SingleShoplistProduct.value.category.id;

        formData.details = {
          name: SingleShoplistProduct.value.name,
          category_id: SingleShoplistProduct.value.category.id,
          sub_category_id: SingleShoplistProduct.value.sub_category?.id || "",
          price_per_unit: SingleShoplistProduct.value.regular_price,
          description: SingleShoplistProduct.value.description || "",
          available: SingleShoplistProduct.value.status == "active",
          image_url: SingleShoplistProduct.value.primary_image_url,
          image_blob: "",
          stock_quantity: SingleShoplistProduct.value.stock_quantity.toString(),
          weight: weight,
          package_dimension_id:
            SingleShoplistProduct.value.dimension_size?.name || "",
          medias: mediaUrls || [],
          medias_blobs: medias || [],
          brand_id: SingleShoplistProduct.value.brand?.id || "",
        };
        productIsAvailable.value =
          SingleShoplistProduct.value.status == "active";
        formData.reward_settings = SingleShoplistProduct.value.rewards.map(
          (item: any) => {
            return {
              percentage: item.percentage,
              quantity: item.quantity,
              type: item.type,
              id: item.id,
            };
          }
        );
        formData.product_commission = {
          active: SingleShoplistProduct.value.affiliateable || false,
          percentage: SingleShoplistProduct.value.affiliate_commission || "1",
        };
        formData.delivery = {
          has_delivery: SingleShoplistProduct.value.deliverable || false,
          areas:
            SingleShoplistProduct.value.delivery_areas?.map((item) =>
              item.delivery_locations_id.toString()
            ) || [],
          shipping_category_id:
            SingleShoplistProduct.value.shipping_category?.category_id?.toString() ||
            "",
        };

        setPricingData();
      }
    };

    const setPageState = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        if (currentPathQuery.uuid?.toString()) {
          hideFields.value = true;
          isEdit.value = true;
          setDefault();
          setTimeout(() => {
            hideFields.value = false;
          }, 300);
        }
      } else {
        isEdit.value = false;
        hideFields.value = false;
      }
    };

    watch(ManyDeliveryLocations, () => {
      setDeliveryLocations();
      if (isEdit.value) {
        selectedAllAreas.value =
          ManyDeliveryLocations.value?.data.length ==
          SingleShoplistProduct.value?.delivery_areas.length;
      }
      setPricingData();
    });

    watch(selectedCategory, () => {
      setSubCategoryOptions();
    });

    watch(productCreationStep, () => {
      setStep();
    });

    watch(formAttributes, () => {
      realignVariantAttributes();
    });

    watch(selectedAllAreas, () => {
      if (selectedAllAreas.value) {
        formData.delivery.areas =
          ManyDeliveryLocations.value?.data.map(
            (singleItem) => singleItem.id
          ) || [];
      } else {
        formData.delivery.areas = [];
      }
    });

    watch(SingleShoplistProduct, () => {
      if (SingleShoplistProduct.value && isEdit.value) {
        setDefault(false);
      }
    });

    onIonViewWillEnter(() => {
      setOptions();
      setSubCategoryOptions();
      getDeliveryLocations();
      setDeliveryLocations();
      setBrandOptions();
      setPackageDimensions();
      setShippingCategoryOptions();
      setPageState();
      setBusinessAdminOptions();
      setPricingData();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("ManyCategories", ManyCategories);
      Logic.Shoplist.watchProperty(
        "ManyDeliveryLocations",
        ManyDeliveryLocations
      );
      Logic.Shoplist.watchProperty(
        "SingleShoplistProduct",
        SingleShoplistProduct
      );
      Logic.Shoplist.watchProperty(
        "ManyPackageDimensions",
        ManyPackageDimensions
      );
      Logic.Shoplist.watchProperty(
        "ManyShippingCategory",
        ManyShippingCategory
      );
      setBusinessAdminOptions();
      Logic.Shoplist.watchProperty("ManyShoplistBrands", ManyShoplistBrands);
      setOptions();

      if (Logic.Common.currentBuildType() == "web") {
        setSubCategoryOptions();
        getDeliveryLocations();
        setPackageDimensions();
        setShippingCategoryOptions();
        setBrandOptions();
        setPricingData();
        setStep();
      }

      setDeliveryLocations();
      setPageState();
    });

    return {
      selectedInventory,
      Logic,
      images,
      sections,
      selectedSection,
      formData,
      formComp,
      formIsValid,
      FormValidations,
      categoryOptions,
      ManyDeliveryLocations,
      deliveryAreas,
      selectedAllAreas,
      isEdit,
      hideFields,
      weightOptions,
      showCustomWeight,
      customWeight,
      updateLoading,
      productCreationStep,
      pageTitle,
      packageDimensionOptions,
      shippingCategoryOptions,
      formAttributes,
      selectedSubCategory,
      selectedCategory,
      subCategoryOptions,
      brandOptions,
      productIsAvailable,
      showAddAttributeAlert,
      removeMedia,
      showAddArea,
      onWeightChange,
      toggleFromDeliveryArea,
      getUrl,
      removeImage,
      continueToNext,
      showAddReward,
      goBackHandler,
      onFileAdded,
      onFileUrlGenerated,
      removeAttribute,
      addAttribute,
      addAttributeValue,
      removeAttributeValue,
      addVariant,
      removeVariant,
      getAttributeOptions,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
});
</script>
