<template>
  <div
    class="w-full flex flex-col space-y-3 py-4 pt-2 relative md:!pb-0 mdlg:!pb-0"
    v-if="data"
  >
    <div
      class="w-full flex flex-row justify-center items-center py-3"
      v-if="data.icon"
    >
      <app-icon :name="data.icon" :customClass="'h-[100px]'" />
    </div>
    <app-normal-text :customClass="'text-left'" class="!text-gray-700">{{
      data.info
    }}</app-normal-text>

    <div class="w-full flex flex-col pt-2 space-y-2">
      <app-button class="w-full py-3" @click="action ? action() : () => {}">
        {{ data.cta_copy }}
      </app-button>

      <app-button
        class="w-full py-3 !bg-transparent !border-[1px] !border-primary-main !text-primary-500"
        @click="action ? action(true) : () => {}"
      >
        Skip
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
    AppButton,
    AppIcon,
  },
  props: {
    data: {
      type: Object as () => {
        info: string;
        cta_copy: string;
        icon: string;
      },
    },
    action: {
      type: Function,
    },
  },
  name: "AppAskForPermission",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
