<template>
  <Transition name="slide">
    <div
      v-if="isVisible"
      :class="`w-full flex flex-row items-center justify-center top-0 left-0  fixed z-[999999999999999] `"
    >
      <div :class="`w-full  mdlg:!w-[65%] lg:!w-[65%] md:w-full  grid grid-cols-12  `">
        <!-- Left side -->
        <div
          class="hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex lg:!flex md:!flex flex-col"
        ></div>

        <!-- Main section -->
        <div
          :class="`flex flex-col space-y-2  col-span-12 px-4  cursor-pointer mdlg:!col-span-6 md:!col-span-8 items-center justify-center mdlg:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 `"
          style="padding-top: calc(env(safe-area-inset-top) + 16px)"
          @click="close"
        >
          <div
            :class="`w-full border-[2px] bg-white dark:bg-gray-900 ${
              setup.type === 'success' ? 'border-green-500' : ''
            }  ${setup.type === 'error' ? 'border-red-500' : ''}  ${
              setup.type === 'info' ? 'border-purple-500' : ''
            } rounded-[40px] px-1 py-1 justify-between items-center flex flex-row `"
          >
            <div class="flex flex-row space-x-3 items-center">
              <AppIcon :name="`alert-${setup.type}`" class="h-[35px]" />
              <app-normal-text class="!font-semibold !line-clamp-1 dark:!text-gray-200">
                {{ setup.message }}
              </app-normal-text>
            </div>

            <div class="flex flex-row justify-end" v-if="setup.action">
              <span
                @click="setup.action?.handler"
                class="px-3 py-[2px] bg-grey-50 rounded-[30px]"
              >
                <app-normal-text class="!text-primary-400">
                  {{ setup.action.text }}
                </app-normal-text>
              </span>
            </div>
          </div>
        </div>

        <!-- Right side -->
        <div
          class="hidden col-span-3 mdlg:!flex mdlg:!col-span-3 flex-col md:!flex md:!col-span-2"
        ></div>
      </div>
    </div>
  </Transition>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
  },
  props: {
    setup: {
      type: Object as () => {
        show: boolean;
        message: string;
        type: "success" | "error" | "info";
        action?: {
          text: string;
          handler: () => void;
        };
      },
      default: () => ({
        show: true,
        message: "Hello alert",
        type: "success",
      }),
    },
  },
  setup() {
    const isVisible = ref(true);

    const close = () => {
      isVisible.value = false;
    };

    onMounted(() => {
      isVisible.value = true;
      // setTimeout(() => {
      //   isVisible.value = false;
      // }, 5000);
    });

    return {
      isVisible,
      close,
    };
  },
});
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-enter-to,
.slide-leave-from {
  transform: translateY(0);
  opacity: 1;
}
</style>
