import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-[165px] flex flex-col space-y-2 !cursor-pointer"
}
const _hoisted_2 = { class: "w-full flex flex-col" }
const _hoisted_3 = { class: "w-full flex flex-col pb-3 left-0 space-y-1" }
const _hoisted_4 = { class: "flex flex-row justify-between items-center" }
const _hoisted_5 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_6 = { class: "!font-normal !text-[#9D4EDD] !text-[12px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_media_box = _resolveComponent("app-media-box")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.hideCategory)
          ? (_openBlock(), _createBlock(_component_app_normal_text, {
              key: 0,
              class: "w-full !text-left !font-light !text-[#313131] dark:!text-grey-200 !text-[12px]"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.data.main_category), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_media_box, {
            data: _ctx.item.data,
            isVideo: !_ctx.item.data.is_grid,
            class: "!h-[200px] !rounded-[7.9px] relative"
          }, null, 8, ["data", "isVideo"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_app_normal_text, { class: "!text-left !line-clamp-1 w-full !font-[550] !text-[12px]" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.data.title), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_normal_text, { class: "!text-[12px] dark:!text-white !font-normal" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
              parseFloat(_ctx.item.data.price?.toFixed(2) || "0"),
              false,
              "ngn"
            )), 1)
              ]),
              _: 1
            }),
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_app_normal_text, { customClass: '!text-[11px] !text-grey-400 dark:!text-gray-200  !font-light !whitespace-nowrap' }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, " ₦" + _toDisplayString(_ctx.numberToAbbrev(_ctx.item.data.points || 0)), 1),
                  _cache[0] || (_cache[0] = _createTextVNode(" Back"))
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}