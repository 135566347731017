import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row h-full items-center justify-center relative" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "w-full flex flex-row items-center justify-between px-4" }
const _hoisted_6 = { class: "flex flex-row space-x-2 items-center justify-center" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex flex-row space-x-3 items-center" }
const _hoisted_9 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_web_side_bar = _resolveComponent("app-web-side-bar")!
  const _component_app_top_bar = _resolveComponent("app-top-bar")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_fixed_container = _resolveComponent("app-fixed-container")!
  const _component_app_board_side_bar = _resolveComponent("app-board-side-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(`h-full w-full mdlg:!w-[70%] lg:!w-[65%]   text-center relative grid grid-cols-12  lg:text-sm mdlg:text-[12px] text-xs bg-white md:!bg-white dark:!bg-black !font-inter`),
      id: `parentContainer-${_ctx.pageKey}`
    }, [
      (_openBlock(), _createBlock(_Teleport, {
        to: "#app",
        disabled: _ctx.Logic.Common.currentBuildType() == 'web'
      }, [
        (
            _ctx.showWebSideBar ||
            _ctx.Logic.Common.currentMediaQuery() != 'md' ||
            (_ctx.Logic.Common.currentMediaQuery() == 'md' &&
              _ctx.Logic.Common.currentBuildType() == 'web')
          )
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col fixed top-0 h-full z-[9999999] md:!pl-4 bg-white md:!bg-white dark:bg-black",
              style: _normalizeStyle(`width: ${_ctx.sideBarConfig.left.width}px; left: ${_ctx.sideBarConfig.left.left}px;`),
              id: `sidbarContainerLeft-${_ctx.pageKey}`
            }, [
              _createVNode(_component_app_web_side_bar, { tabIsActive: _ctx.tabIsActive }, null, 8, ["tabIsActive"])
            ], 12, _hoisted_3))
          : _createCommentVNode("", true)
      ], 8, ["disabled"])),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col sticky top-0" }, null, -1)),
      _createElementVNode("div", {
        class: _normalizeClass(`col-span-12 mdlg:!col-span-6 md:!col-span-8 flex flex-col h-full mdlg:!min-h-screen mdlg:!border-l-[1px] md:!min-h-screen md:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-grey-100  mdlg:dark:!border-grey-700 md:dark:!border-grey-700 ${
          _ctx.Logic.Common.currentBuildType() == 'web' ? '' : 'overflow-y-auto'
        }`),
        id: `container-${_ctx.pageKey}`
      }, [
        (
            _ctx.pageKey != 'home'
              ? true
              : _ctx.Logic.Common.currentMediaQuery() == 'xs' ||
                _ctx.Logic.Common.currentMediaQuery() == 'sm'
          )
          ? (_openBlock(), _createBlock(_component_app_top_bar, {
              key: 0,
              "has-notify": _ctx.hasNotify,
              "has-search": _ctx.hasSearch,
              "has-story": _ctx.hasStory,
              "has-shopping-basket": _ctx.hasShoppingBasket,
              title: _ctx.title,
              type: _ctx.topBarType,
              hasAddButton: _ctx.hasAddButton,
              addButtonAction: _ctx.addButtonAction,
              openSideBar: 
            () => {
              _ctx.Logic.Common.currentMediaQuery() == 'xs' ||
              _ctx.Logic.Common.currentMediaQuery() == 'sm'
                ? (_ctx.showSideBar = true)
                : (_ctx.showSideBar = false);
            }
          ,
              showStory: 
            () => {
              _ctx.setStoryContent(_ctx.ContentType.Explore, {
                id: 0,
                type: 'collection',
              });
            }
          ,
              greetings: _ctx.greetings,
              "top-bar-style": 'bg-white dark:bg-white'
            }, {
              "extra-top-bar-right": _withCtx(() => [
                _renderSlot(_ctx.$slots, "extra-top-bar-right")
              ]),
              _: 3
            }, 8, ["has-notify", "has-search", "has-story", "has-shopping-basket", "title", "type", "hasAddButton", "addButtonAction", "openSideBar", "showStory", "greetings"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "extra-top-bar"),
        (_ctx.showMinimalTopBar)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(`w-full flex flex-col sticky top-0 !bg-white dark:!bg-black z-50 pb-2 ${_ctx.topBarVisibility}`),
              style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_app_image_loader, {
                  photoUrl: 
                _ctx.Logic.Common.currentAccountType() == 'merchant'
                  ? _ctx.Logic.Auth.GetCurrentBusiness()?.photo_url || ''
                  : _ctx.Logic.Auth.AuthUser?.profile?.photo_url || ''
                  ? _ctx.Logic.Auth.AuthUser?.profile?.photo_url || ''
                  : '/images/avatar-1.png'
              ,
                  customClass: '!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200',
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSideBar = true))
                }, null, 8, ["photoUrl"]),
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.isHome)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options, (option, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "flex flex-col space-y-1 items-center justify-center w-[30px]",
                          key: index,
                          onClick: ($event: any) => (option.action())
                        }, [
                          _createVNode(_component_app_icon, {
                            name: option.icon,
                            customClass: '!h-[30px]'
                          }, null, 8, ["name"])
                        ], 8, _hoisted_7))
                      }), 128))
                    : (_openBlock(), _createBlock(_component_app_header_text, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ]),
                        _: 1
                      }))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.Logic.Common.currentAccountType() == 'client')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.isHome)
                          ? (_openBlock(), _createBlock(_component_app_icon, {
                              key: 0,
                              name: `shopping-basket-circle${
                      _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`,
                              customClass: '!h-[38px]'
                            }, null, 8, ["name"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (_ctx.hasNotify)
                                ? (_openBlock(), _createBlock(_component_app_icon, {
                                    key: 0,
                                    name: `notify-circle${
                      _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`,
                                    customClass: '!h-[38px]'
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (_ctx.hasSearch)
                                ? (_openBlock(), _createBlock(_component_app_icon, {
                                    key: 1,
                                    name: `search-circle${
                      _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`,
                                    customClass: '!h-[38px]'
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (_ctx.hasAddButton)
                                ? (_openBlock(), _createBlock(_component_app_icon, {
                                    key: 2,
                                    name: `add-circle${
                      _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`,
                                    customClass: '!h-[38px] cursor-pointer',
                                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addButtonAction()))
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (_ctx.hasStory)
                                ? (_openBlock(), _createBlock(_component_app_icon, {
                                    key: 3,
                                    name: `story-circle${
                      _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`,
                                    customClass: '!h-[38px] cursor-pointer',
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (
                      _ctx.setStoryContent(_ctx.ContentType.Explore, {
                        id: 0,
                        type: 'collection',
                      })
                    ))
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (_ctx.hasShoppingBasket)
                                ? (_openBlock(), _createBlock(_component_app_icon, {
                                    key: 4,
                                    name: `shopping-basket-circle${
                      _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`,
                                    customClass: '!h-[38px]'
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true)
                            ], 64))
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.hasNotify || !_ctx.hasAddButton)
                          ? (_openBlock(), _createBlock(_component_app_icon, {
                              key: 0,
                              name: `notify-circle${
                    _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                  }`,
                              customClass: '!h-[38px] cursor-pointer',
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/others/notifications')))
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true),
                        (_ctx.hasAddButton)
                          ? (_openBlock(), _createBlock(_component_app_icon, {
                              key: 1,
                              name: `add-circle${
                    _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                  }`,
                              customClass: '!h-[38px] cursor-pointer',
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addButtonAction()))
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true)
                      ], 64))
                ])
              ]),
              _renderSlot(_ctx.$slots, "extra-top-bar")
            ], 2))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(_component_app_fixed_container, {
          class: "w-full bottom-0 left-0 bg-white md:!bg-white dark:bg-black z-[9999999]",
          style: {"height":"calc(env(safe-area-inset-bottom))"}
        })
      ], 10, _hoisted_4),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex flex-col sticky top-0" }, null, -1)),
      _createElementVNode("div", {
        class: "hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full",
        style: _normalizeStyle(`width: ${_ctx.sideBarConfig.left.width}px; right: ${_ctx.sideBarConfig.left.left}px;`),
        id: `sidbarContainerRight-${_ctx.pageKey}`
      }, [
        _createVNode(_component_app_board_side_bar)
      ], 12, _hoisted_9)
    ], 8, _hoisted_2)
  ]))
}